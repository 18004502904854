import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ManagerService {

  readonly apiBaseUrl = environment.apiUrl;
  readonly getCandidaturesManagerByIdUrl = `${this.apiBaseUrl}/candidates/manager/`;

  constructor(private http: HttpClient) { }

  /**
   * This function gets manager's candidates 
   * @param idEmployee is the managerId 
   * @returns from a get request list of details of passed tests
   */
  public getCandidaturesManagerById(idEmployee: string): Observable<any> {
    return this.http.get<any>(`${this.getCandidaturesManagerByIdUrl}${idEmployee}`);
  }
}
