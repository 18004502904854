import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators, FormArray } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { TechnologyService } from "../../candidatures/shared/technology.service";
import { TranslateService } from "@ngx-translate/core";
import { ImageService } from "../shared/image.service";
import { Subscription } from "rxjs";
import { Technology } from "../../candidatures/shared/technology";
@Component({
  selector: "app-technologies-library",
  templateUrl: "./technologies-library.component.pug",
  styleUrls: ["./technologies-library.component.scss"],
})
export class TechnologiesLibraryComponent implements OnInit {
  technologyForm: FormGroup;
  isValideForm = false;
  technology: Technology = new Technology();
  isValid: any = {
    settedTitle: true,
  };
  submitted = false;


  private idCategory: string;

  constructor(
    public router: Router,
    public route: ActivatedRoute,

    private toastr: ToastrService,
    private technologyService: TechnologyService,
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
    this.idCategory = this.route.snapshot.params['section_name'];
    this.initForm()
  }

  initForm() {
    this.technologyForm = this.fb.group({
      idTechnology: null,
      title: ["", Validators.required]
    });
  }

  onEdit(idCategory: string) {
    this.technology = {
      idTechnology: "",
      name: this.technologyForm.value.title,
      idCategory: idCategory,
      subTechnologies: []
    }
    this.technologyService.createTechnology(this.technology).subscribe(
      (data: Technology) => {
        this.router.navigate([`/categories/${idCategory}`]);
        this.toastr.success(`Technologie ${data.name} crée`)
      },
      (err) => {
        this.toastr.error("Erreur lors de la création de la technologie");
      }
    )
  }

  validateInput() {
    this.technologyForm.value.title.length == 0 ? this.isValid.settedTitle = false : this.isValid.settedTitle = true;
    if (this.isValid.settedTitle) this.isValideForm = true;
  }

  onSubmit() {
    this.submitted = true;
    this.validateInput();

    if (this.isValideForm) {
      this.onEdit(this.idCategory)
    } else {
      this.toastr.error("Tu dois valider tous les champs");
    }
  }

}
