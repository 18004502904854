import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Candidature } from './candidature.model';
import * as jsPDF from 'jspdf/dist/jspdf.node.min.js';
import html2canvas from 'html2canvas';

@Injectable({
  providedIn: 'root'
})
export class CandidatureService {
  readonly apiBaseUrl = environment.apiUrl;

  readonly getCandidatureByIdTestUrl = `${this.apiBaseUrl}/test/`;
  readonly getEosPhotoByEmailUrl = `${this.apiBaseUrl}/employees/eosApi/`;
  readonly getDYBByEmailUrl = `${this.apiBaseUrl}/test/dyb/`;

  constructor(private http: HttpClient) { }

  public getCandidatureByIdTest(idtest: string):Observable<Candidature>{
    return this.http.get<Candidature>(`${this.getCandidatureByIdTestUrl}${idtest}`);
  }

  public getEosPhotoByEmail(emailAdress: string):Observable<any>{
    return this.http.get(`${this.getEosPhotoByEmailUrl}${emailAdress}`);
  }

  public getDYBByEmail(emailAdress: string):Observable<any>{
    return this.http.get(`${this.getDYBByEmailUrl}${emailAdress}`);
  }

  exportDetailsCandidatureAsPdf(
    document: any,
    fileName: string,
    orientation: string
  ){
    let options = { useCORS: true, allowTaint : false, backgroundColor: '#0b395a' };
    html2canvas(document, options).then(canvas => {
      let img = canvas.toDataURL('image/png');
      let doc = new jsPDF({
        orientation: orientation ? orientation : 'l',
        unit: 'px',
        format: [canvas.width * 3 / 4, canvas.height * 3 / 4]
      });
      doc.addImage(img, 'JPEG', 0, 0);
      doc.setProperties({
          title: fileName
      });
      window.open(doc.output('bloburl'));
    });
    return true;
  }

  public getProfilePictureAsbase64Data(imageUrl: string):Observable<Blob>{
    const requestOptions: Object = {
      responseType: 'blob'
    }
    return this.http.get<any>(imageUrl , requestOptions);
  }
}
