import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { QuestionService } from "../shared/question.service";
import { Technology } from "../shared/technology.model";
import { filterByAlphabetical } from '../../../shared/_helpers/functions'

@Component({
  selector: "app-questions-library",
  templateUrl: "./questions-library.component.pug",
  styleUrls: ["./questions-library.component.scss"],
})
export class QuestionsLibraryComponent implements OnInit {
  paramsSubscription: Subscription;
  initialTechnologiesList: Technology[] = [];
  technologiesList: Technology[] = [];
  url: string;
  id: string;
  searchString: string = '';

  public section_name: string;
  constructor(
    public router: Router,
    private questionService: QuestionService,
    public route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.paramsSubscription = this.route.params.subscribe((params: any) => {
      this.section_name = params["section_name"];
      this.id = params["section_name"];
      this.getTechnologyById(this.section_name);
    },
      (err) => {
        console.error(err);
      });

    this.url = this.router.url;
    this.id = this.section_name;
  }

  getTechnologyById(id: string) {
    this.questionService.getTechnologiesByCategory(id).subscribe(technos => {
      filterByAlphabetical(technos)
      this.technologiesList = technos;
      this.initialTechnologiesList = [...technos];
    })
  }

  addSubTechno(idTechno) {
    localStorage.setItem('idTechnology', idTechno.toString());

    this.router.navigate([
      "/categories/" + this.section_name + "/subtechnology"
    ])
  }

  sousTechno(id: string, name: string) {
    this.router.navigate([
      "/categories/" + this.section_name + "/subtechnology",
      id,
    ]);
    this.questionService.loadValue(name);
  };

  goToAddTechology() {
    this.router.navigate(['/categories', this.id, "technology"]);
  };

  getSearchFilterValue(event) {
    this.searchString = event;
    // Rechercher tes technologies dont le nom contient le searchText ou les technos où les subtechno contient le searchText
    let technologiesListfilter = this.initialTechnologiesList.filter(techno => techno.name.toLowerCase().includes(this.searchString.toLowerCase()) ||
      techno.subTechnologies[0] !== null && techno.subTechnologies.some(sunTechno => sunTechno.name.toLowerCase().includes(this.searchString.toLowerCase())));
    // filtrer le tableu des subtechno pour n'avoir que les subtechnos qui contiennet le searchText
    let filterBySubTechno = technologiesListfilter.map(techno => {
      if (techno.name.toLowerCase() === this.searchString.toLowerCase()) {
        return techno;
      } else {
        return {
          ...techno,
          subTechnologies: techno.subTechnologies.filter(subTechno => techno.subTechnologies[0] !== null && subTechno.name.toLowerCase().includes(this.searchString.toLowerCase()))
        };
      }
    });
    this.technologiesList = this.searchString ? filterBySubTechno : this.initialTechnologiesList
  }

}
