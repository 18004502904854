import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDatepickerModule, MatInputModule, MatSelectModule, MAT_DATE_LOCALE, MatCheckboxModule } from '@angular/material';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';

import { MaterialModule } from '../../material.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { CandidaturesDialog } from './candidatures-dialog/candidatures-dialog.component';
import { CandidaturesResultDialogComponent } from './candidatures-dialog/candidatures-result-dialog/candidatures-result-dialog.component';
import { CandidaturesListComponent } from './candidatures-list/candidatures-list.component';
import { CandidaturesSynthesisComponent } from './candidatures-synthesis/candidatures-synthesis.component';
import { CandidaturesViewComponent } from './candidatures-view/candidatures-view.component';
import { candidaturesComponents } from './candidatures.routes';

@NgModule({
  imports: [
    MatDatepickerModule,
    SharedModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    MatPaginatorModule,
    MatSortModule,
    MaterialModule,
    MatCheckboxModule
  ],
  declarations: [candidaturesComponents],
  entryComponents: [
    CandidaturesDialog,
    CandidaturesResultDialogComponent,
    CandidaturesSynthesisComponent
  ],
  providers: [
    MatCheckboxModule,
    MatDatepickerModule,
    {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'}
  ]
})

export class CandidatureModule { }

