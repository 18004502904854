import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../material.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UsersComponent } from './users.component';
import { UsersListComponent } from './users-list/users-list.component';
import { AddUserDialogComponent } from './users-dialog/add-user-dialog/add-user-dialog.component';
import { UpdateUserDialogComponent } from './users-dialog/update-user-dialog/update-user-dialog.component';
import { DeleteUserDialogComponent } from './users-dialog/delete-user-dialog/delete-user-dialog.component';

@NgModule({
  declarations: [UsersComponent, UsersListComponent, AddUserDialogComponent, UpdateUserDialogComponent, DeleteUserDialogComponent],
  entryComponents: [
    AddUserDialogComponent,
    UpdateUserDialogComponent,
    DeleteUserDialogComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ]
})
export class UsersModule { }
