import { CandidaturesSynthesisComponent } from './candidatures-synthesis/candidatures-synthesis.component';
import { CandidaturesViewComponent } from './candidatures-view/candidatures-view.component';
import { AuthGuard } from '../../shared/_guards/auth.guard';
import { CandidaturesDialog } from './candidatures-dialog/candidatures-dialog.component';
import { CandidaturesResultDialogComponent } from './candidatures-dialog/candidatures-result-dialog/candidatures-result-dialog.component';
import { CandidaturesListComponent } from './candidatures-list/candidatures-list.component';

export const CANDIDATURES_ROUTES = [
  { path: '', component: CandidaturesSynthesisComponent, canActivate: [AuthGuard] },
  { path: 'more/:id', component: CandidaturesViewComponent, canActivate: [AuthGuard] },
];

export const candidaturesComponents = [
  CandidaturesDialog, 
  CandidaturesResultDialogComponent,
  CandidaturesListComponent,
  CandidaturesSynthesisComponent,
  CandidaturesViewComponent
]