import { QuestionsLibraryComponent } from './questions-library/questions-library.component';
import { AuthGuard } from 'src/app/shared/_guards/auth.guard';
import { SubtechnologyQuestionsComponent } from './subtechnology-questions/subtechnology-questions.component';
import { DisplayQuestionType } from './shared/question-type.pipe';
import { LibraryByFolderComponent } from './library-by-folder/library-by-folder.component';
import { QuestionsEditComponent } from './questions-edit/questions-edit.component';
import { TechnologiesLibraryComponent } from './technologies-library/technologies-library.component'
import { SubTechnologyLibraryComponent } from './shared/subtechnology-library/subTechnology-library.component';

export const QUESTIONSLIBRARY_ROUTES= [
    { path: '', component: LibraryByFolderComponent},
    { path: ':section_name/subtechnology/:id', component: SubtechnologyQuestionsComponent, canActivate: [AuthGuard]},
    { path: ':section_name', component: QuestionsLibraryComponent},
    { path: ':section_name/subtechnology/:id/question/:idquestion', component: QuestionsEditComponent, canActivate: [AuthGuard]},
    { path: ':section_name/subtechnology/:id/question', component: QuestionsEditComponent, canActivate: [AuthGuard]},
    { path: ':section_name/technology', component: TechnologiesLibraryComponent, canActivate: [AuthGuard]},
    { path: ':section_name/subtechnology', component: SubTechnologyLibraryComponent, canActivate: [AuthGuard]}
]

export const LibraryComponents = [
    LibraryByFolderComponent,
    QuestionsLibraryComponent,
    SubtechnologyQuestionsComponent,
    DisplayQuestionType,
    QuestionsEditComponent,
    TechnologiesLibraryComponent,
    SubTechnologyLibraryComponent
]
