export const environment = {
  name: "prod",
  production: true,
  version: 1,
  newLabelTechnologiesFrontAdmin: false,
  getManagerPictureFromEosApi: false,
  pagination: false,
  toggle_renamenavbar: false,
  rememberMe: false,
  apiUrl: "https://api-appskills.davidson.group",
  adminAppUrl: "https://admin-appskills.davidson.group",
  frontVersion: "2",
};
