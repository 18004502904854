import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { CandidatureModule } from './modules/candidatures/candidatures.module';
import { SharedModule } from './shared/shared.module';
import { TestModule } from './modules/tests/tests.module';
import { LoginModule } from './modules/login/login.module';
import { MyProfileModule } from './modules/my-profile/my-profile.module';
import { UsersModule } from './modules/users/users.module';
import { DetailsCandidatureModule } from './modules/details-candidature/details-candidature.module';
import { CustomHeadersInterceptor } from './shared/_helpers/custom-headers.interceptor';
import { ErrorInterceptor } from './shared/_helpers/error.interceptor';
import { CookieService } from 'ngx-cookie-service';
import { QuestionsLibraryModule } from './modules/questions-library/questions-library.module';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SharedModule,
    CandidatureModule,
    TestModule,
    MyProfileModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LoginModule,
    UsersModule,
    QuestionsLibraryModule,
    DetailsCandidatureModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHeadersInterceptor,
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    CookieService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
