import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class RolesService {

  constructor() { }

  isAdmin(): boolean {
    let user: any = JSON.parse(localStorage.getItem('CURRENT_USER'));
    return user.role === 'admin';
  }

  isManager(): boolean {
    let user: any = JSON.parse(localStorage.getItem('CURRENT_USER'));
    return user.role === 'manager';
  }

  isReader(): boolean {
    let user: any = JSON.parse(localStorage.getItem('CURRENT_USER'));
    return user.role === 'lecture';
  }
}
