import { Component, OnInit } from '@angular/core';
import { CandidaturesService } from '../shared/candidatures.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Candidature } from '../shared/candidature';
import { switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-candidatures-view',
  templateUrl: './candidatures-view.component.pug',
  styleUrls: ['./candidatures-view.component.scss']
})
export class CandidaturesViewComponent implements OnInit {
  candidature: Candidature;
  aptitudes: any[] = [
    { libelle: 'Js', score: 78 },
    { libelle: 'Gestion de projet', score: 58 },
    { libelle: 'Big Data', score: 48 },
    { libelle: 'Java', score: 90 }
  ];

  constructor(
    private candidatureService: CandidaturesService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.paramMap
      .pipe(
        switchMap((params: ParamMap) =>
          this.candidatureService.getCandidatureByIdTest(params.get('id'))
        )
      )
      .subscribe(data => {
        this.candidature = data;
      });
  }
}
