import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-delete-question-dialog',
  templateUrl: './delete-question-dialog.component.pug',
  styleUrls: ['./delete-question-dialog.component.scss']
})
export class DeleteQuestionDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DeleteQuestionDialogComponent>
  )
  { }

  ngOnInit() {
  }

  /**
   * @description Sends confirmation
   */
  public onSubmit(): void {
    this.dialogRef.close(true);
  }

  /**
   * @description Closes modal
   */
  public onClose(): void {
    this.dialogRef.close();
  }

}
