import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FiltersService {

  /**
  * This function if a element already exist in a element array 
  * @param element element choose by the user
  * @param elementArray array containing elements already chose before
  * @return boolean
  */
  checkExistingFilter(element, elementArray): boolean {
    let bool: boolean = false;
    elementArray.forEach(e => {
      if (e === element) {
        bool = true;
      }
    });

    return bool;
  }

  /**
  * This function if a element already exist in a element array 
  * @param element element choose by the user
  * @param elementArray array containing elements already chose before
  * @return new elementArray
  */
  removeFilter(element, elementArray): Array<any> {
    elementArray.forEach(e => {
      if (e === element) {
        elementArray.splice(elementArray.indexOf(element), 1)
      }
    });
    return elementArray;      
  }
}
