import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';


@Component({
  selector: 'app-language-box',
  templateUrl: './language-box.component.pug',
  styleUrls: ['./language-box.component.scss']
})
export class LanguageBoxComponent implements OnInit {

  @Output() public langChanged: EventEmitter<any> = new EventEmitter<any>();

  cookieValue = null;


  constructor(
    private translate: TranslateService,
    private cookieService: CookieService,
    ) { }

  ngOnInit() {
  }

  switchLanguage(lang: string):void  {
    this.cookieService.set('LANG', lang);
    this.translate.use(lang);
    this.langChanged.emit(lang);
	}

}
