import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators, FormArray } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { QuestionService } from "../shared/question.service";
import { LevelService } from "../shared/level.service";
import { SubTechnologyService } from "../shared/sub-technology.service";
import { Question } from "../shared/question.model";
import { Answer } from "../shared/answer.model";
import { ImageService } from "../shared/image.service";
import { environment } from "src/environments/environment";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-questions-edit",
  templateUrl: "./questions-edit.component.pug",
  styleUrls: ["./questions-edit.component.scss"],
})
export class QuestionsEditComponent implements OnInit {
  questionForm: FormGroup;
  section_name: String;
  idSubTechnology: string;
  idQuestion: string;
  subTechName: string;
  question: Question = new Question();
  listLevels: Array<any>;
  answerImages=[]
  alpha: string[] = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];
  file: File;
  files = [];
  isFileChanged: boolean = false;
  url: string = environment.apiUrl + "/";
  submitted = false;
  isValideForm = false;
  imageData = null;
  questionTime: any = {
    minutes: 0,
    secondes: 0,
  };
  isValid: any = {
    settedTime: true,
    settedTitle: true,
    settedQuestion: true,
    settedType: true,
    settedAnswers: true,
    settedCorrectnesse: true,
    settedCorrectTime: true,
  };
  oldQuestionPictureUrl: string = ""
  questionImage: string = ""
  oldAnswersPictureUrl={}

  constructor(
    private fb: FormBuilder,
    private questionService: QuestionService,
    private activatedRoute: ActivatedRoute,
    private levelService: LevelService,
    private ImageService: ImageService,
    private toastr: ToastrService,
    private route: Router,
    private subTechnoService: SubTechnologyService,
  ) {}

  ngOnInit() {
    this.initForm();
    this.getIdQuestion();
    this.getSectionName();
    this.getSubTechnologyId();
    this.getSubTechnologyName();
    this.isFileChanged = false;
  }

  initForm() {
    this.questionForm = this.fb.group({
      idquestion: null,
      time: ["", Validators.required],
      title: ["", Validators.required],
      question: ["", Validators.required],
      type: ["", Validators.required],
      answers: this.fb.array([], Validators.minLength(2)),
    });
    this.questionTime.minutes = 0;
    this.questionTime.secondes = 0;
  }

  get answersForm() {
    return this.questionForm.get("answers") as FormArray;
  }

  addAnswerWithData(data: Array<Answer>) {
    let answersForm = this.fb.array([]);
    data.forEach((element) => {
      const answer = this.fb.group({
        idAnswer: element.idAnswer,
        answer: [element.answer, Validators.required],
        keyWords: element.keyWords,
        isCorrect: [element.isCorrect, Validators.required],
        questionId: element.questionId,
        answerPictureUrl: element.answerPictureUrl,
      });
      answersForm.push(answer);
    });
    return answersForm;
  }

  onCreate() {
    for (let index = 0; index < 2; index++) {
      this.addAnswer();
    }
  }

  onEdit(idQuestion: string) {
    this.questionService.getQuestionById(idQuestion).subscribe((data) => {
      this.question = data;
      data.answers.map((answer,index)=>this.answerImages.push({answerPictureUrl:answer.answerPictureUrl}))
      this.oldQuestionPictureUrl = data.question_picture_url ? data.question_picture_url : '';
      data.answers.map((answer,i)=>this.oldAnswersPictureUrl[i]=answer.answerPictureUrl)
      this.populateForm(this.question)
    });
  }

  populateForm(question: Question) {
    this.questionTime.minutes = Math.floor(question.time / 60);
    this.questionTime.secondes = question.time % 60;
    this.questionForm = this.fb.group({
      idquestion: question.idquestion,
      time: [this.questionTime, Validators.required],
      title: [question.title, Validators.required],
      question: [question.question, Validators.required],
      type: [question.type, Validators.required],
      answers: this.addAnswerWithData(question.answers),
    });
  }

  getSelectedLevelsByQuestionID(
    idQuestion: string,
    questionsList: any[]
  ): Array<any> {
    // we should use this.question (getquestionbyid)
    // get levels
    const levels = questionsList.find(
      (question) => question.idquestion == idQuestion
    ).levels;
    return levels.map((level) => {
      return {
        ...level,
        levelId: levels.find((idLevel) => idLevel.name == level.name).idLevel,
      };
    });
  }

  getIdQuestion() {
    this.activatedRoute.params.subscribe((params) => {
      params["idquestion"]
        ? this.onEdit(params["idquestion"])
        : this.onCreate();
      this.idQuestion = params["idquestion"];
    });
  }

  addAnswer() {
    const answer = this.fb.group({
      idAnswer: "",
      answer: ["", Validators.required],
      keyWords: "",
      isCorrect: [null, Validators.required],
      questionId: "",
      answerPictureUrl: null
    });
    this.answersForm.push(answer);
    this.answerImages.push({answerPictureUrl:""})
  }

  deleteAnswer(i) {
    const answerRemoved=this.answersForm.value.find((answer,index)=>i===index)
    this.answersForm.removeAt(i);
    this.answerImages=this.answerImages.filter((answer,index)=>index!==i)
    if(this.idQuestion){
      if(answerRemoved.answerPictureUrl){
        this.removeUploadedImage(answerRemoved.answerPictureUrl,i)
      }
    }
  }

  getSectionName() {
    this.activatedRoute.params.subscribe((params: any) => {
      this.section_name = params["section_name"];
    });
  }

  getSubTechnologyId() {
    this.activatedRoute.params.subscribe((params) => {
      this.idSubTechnology = params["id"];
    });
  }

  getSubTechnologyName() {
    this.subTechnoService.getSubTechnologyById(this.idSubTechnology).subscribe(
      (subTech) => {
        this.subTechName = subTech.name;
      },
      (err) => {
        console.error(err);
      }
    );
  }

  isQuestionLevel(level: string): boolean {
    let levels = this.questionForm.value.levels;
    for (let index = 0; index < levels.length; index++) {
      if (levels[index].name == level) return true;
    }
    return false;
  }

  isQuestionType(type: string): boolean {
    if (this.questionForm.value.type == type) return true;
    else return false;
  }

  addLevel(level: any) {
    let levels = this.questionForm.value.levels;
    let done: boolean = false;
    for (let index = 0; index < levels.length; index++) {
      if (levels[index].name == level.name) {
        this.questionForm.value.levels.splice(index, 1);
        done = true;
      }
    }
    if (!done) {
      this.questionForm.value.levels.push(level);
    }
  }

  validateInput() {
    this.isValid.settedCorrectTime = true
    if (this.questionTime.secondes == 0 && this.questionTime.minutes == 0)
      this.isValid.settedTime = false;

    if (this.questionTime.secondes < 0 || this.questionTime.minutes < 0)
      this.isValid.settedCorrectTime = false;

    if (this.questionForm.value.title.length == 0)
      this.isValid.settedTitle = false;

    if (this.questionForm.value.question.length == 0)
      this.isValid.settedQuestion = false;

    if (this.questionForm.value.type.length == 0)
      this.isValid.settedType = false;

    if (!this.isValidAnswers()) this.isValid.settedAnswers = false;

    if (!this.isValidCorrectnesse()) this.isValid.settedCorrectnesse = false;

    if (
      this.isValid.settedTime &&
      this.isValid.settedCorrectTime &&
      this.isValid.settedTitle &&
      this.isValid.settedQuestion &&
      this.isValid.settedType &&
      this.isValid.settedAnswers &&
      this.isValid.settedCorrectnesse 
    )
      this.isValideForm = true;
  }

  isValidAnswers(): boolean {
    let answers: [Answer] = this.questionForm.value.answers;
    for (let index = 0; index < answers.length; index++) {
      if (!answers[index].answer && !this.answerImages[index].answerPictureUrl) {
        this.isValid.settedAnswers = false;
        return false;
      }
    }
    return true;
  }

  isValidCorrectnesse(): boolean {
    let answers: [Answer] = this.questionForm.value.answers;
    for (let index = 0; index < answers.length; index++) {
      if (answers[index].isCorrect == null) {
        this.isValid.settedCorrectnesse = false;
        return false;
      }
    }
    return true;
  }

  doesNotContain(): boolean {
    let cpt: number = 0;
    let answers: [Answer] = this.questionForm.value.answers;
    if (this.questionForm.value.type == "multiple_choice") return true;
    if (this.questionForm.value.type == "one_choice") {
      for (let index = 0; index < answers.length; index++) {
        if (answers[index].isCorrect == true) {
          cpt = cpt + 1;
          break;
        }
      }
      if (cpt == 0) return true;
    }

    return false;
  }

  removeUploadedImage(answerPicture?,i?) {
    let pictureName = ""
    if(answerPicture){
      pictureName=answerPicture.split('/')[2]
      this.answerImages=this.answerImages.map((image,index)=>index===i?{...image,answerPictureUrl:""}:image)
      if(this.idQuestion){
        this.question.answers[i].answerPictureUrl = null;
      }
    }else{
      pictureName = this.question.question_picture_url && this.question.question_picture_url.split('/')[2]
      this.question.question_picture_url = "";
      this.questionImage = ""
      this.files=this.files.filter((file)=>!file.name.includes('question'))
    }
    
    if(this.idQuestion){
      this.questionService.deleteImage(pictureName).subscribe(
        (result) => {
          // Aucune action nécessaire ici
        },
        (err) => {
          console.error('Erreur lors de la requête DELETE', err)
        }
      )
    }

  }

  fileChange(event: Event, index?: number) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files && inputElement.files[0]) {
      const imageData = inputElement.files[0];
      const reader = new FileReader();
      reader.onload = (event: any) => {
        // Mettez à jour l'image pour la réponse spécifique
        if(Number.isInteger(index)){
          this.answerImages=this.answerImages.map((answer,i)=>index===i?{answerPictureUrl:event.target.result}:answer)
          this.questionForm.value.answers[index].answerPictureUrl = event.target.result;
        }else{
          this.questionImage = event.target.result;
        }
        this.ImageService.resizeImage(imageData, 720, 720).then(
          (blob) => {
            // Mettez à jour l'aperçu de l'image pour la réponse
            const imageElement = Number.isInteger(index)? document.getElementById("answerImgPreview" + index): document.getElementById("questionImgPreview")
            if (imageElement) {
              imageElement.setAttribute("src", URL.createObjectURL(blob));
            }
          },
          (err) => {
            console.error("Photo error", err);
          }
        );
      };
      reader.readAsDataURL(inputElement.files[0]);
      // Mettez à jour le fichier pour la réponse spécifique
      const file=inputElement.files[0]
      const fileNameToArray=file.name.split('.')
      const ext=fileNameToArray[fileNameToArray.length-1]
      const newFileName = Number.isInteger(index) ? `answerImage${index}.${ext}` : `questionImage.${ext}`
      const newFile = new File([imageData], newFileName, { type: file.type });
      this.files.push(newFile);
    }
  }

  onMinutesInput(value: number) {
    if (Number(value) <= 30) {
      this.questionTime.minutes = Number(value);
    } else {
      this.questionTime.minutes = 0;
    }
    if (this.questionTime.minutes >= 0 && this.questionTime.secondes >= 0) {
      this.isValid.settedCorrectTime = true;
    }
  }

  onSecondesInput(value: number) {
    if (Number(value) <= 55) {
      this.questionTime.secondes = Number(value);
    } else {
      this.questionTime.secondes = 0;
    }
    if (this.questionTime.minutes >= 0 && this.questionTime.secondes >= 0) {
      this.isValid.settedCorrectTime = true;
    }
  }

  increment(time: string) {
    switch (time) {
      case "minutes":
        if (this.questionTime.minutes < 30) {
          this.questionTime.minutes++;
        }
        break;
      case "secondes":
        if (this.questionTime.secondes < 55) {
          this.questionTime.secondes++;
        }
        break;
      default:
        break;
    }
  }

  decrement(time: string) {
    switch (time) {
      case "minutes":
        if (this.questionTime.minutes > 0) {
          this.questionTime.minutes--;
        }
        break;
      case "secondes":
        if (this.questionTime.secondes > 0) {
          this.questionTime.secondes--;
        }
        break;
      default:
        break;
    }
  }

  saveData() {
    const QuestionForm: any = new FormData()
    let answers
    QuestionForm.append('priority', '1')
    QuestionForm.append('question', this.questionForm.value.question)
    QuestionForm.append('subTechnologyId', this.idSubTechnology)
    QuestionForm.append('time', (this.questionTime.minutes * 60 + this.questionTime.secondes))
    QuestionForm.append('title', this.questionForm.value.title)
    QuestionForm.append('type', this.questionForm.value.type)
    if(this.files && this.files.length){
      for(let i = 0; i<this.files.length; i++){
        QuestionForm.append('files', this.files[i])
      }
    }
    
    if (this.idQuestion) {
      QuestionForm.append('idquestion', this.idQuestion.toString())
      answers=this.questionForm.value.answers.map((answer,index)=>{
        if(answer.answerPictureUrl && this.question.answers[index]){
          return {...answer,answerPictureUrl:this.question.answers[index].answerPictureUrl}
        }else{
          return {...answer,answerPictureUrl:null}
        }
      })
      let questionPictureUrl=this.question.question_picture_url?this.question.question_picture_url:""
      QuestionForm.append('question_picture_url', questionPictureUrl)
      QuestionForm.append('old_question_picture_url', this.oldQuestionPictureUrl)
      QuestionForm.append('oldAnswersPictureUrl', JSON.stringify(this.oldAnswersPictureUrl))
      QuestionForm.append('answers', JSON.stringify(answers))
      this.questionService.updateQuestion(QuestionForm, this.idQuestion).subscribe(
        (data) => {
          this.goToQuestion();
        },
        (err) => {
          console.error(err);
        }
      );
    } else {
      answers=this.questionForm.value.answers.map(answer=>{
        return {...answer,answerPictureUrl:null}
      })
      QuestionForm.append('question_picture_url', null)
      QuestionForm.append('answers', JSON.stringify(answers))
      this.questionService.createQuestion(QuestionForm).subscribe(
        (data) => {
          this.goToQuestion();
        },
        (err) => {
          console.error(err);
        }
      );
    }
  }

  getQuestionTime(): number {
    return this.questionTime.minutes * 60 + this.questionTime.secondes;
  }

  goToQuestion() {
    this.route.navigate([
      "/categories",
      this.section_name,
      "subtechnology",
      this.idSubTechnology,
    ]);
  }

  onSubmit() {
    this.submitted = true;
    this.validateInput();
    if (this.isValideForm) {
      this.saveData();
    } else {
      this.toastr.error("Tu dois valider tous les champs");
    }
  }
}
