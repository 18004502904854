import { Component, OnInit, ViewChild} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UsersService } from '../../users/shared/users.service';
import { Router } from '@angular/router';
import { User } from '../shared/user.model';
import { MustMatch } from 'src/app/shared/services/must-match.validator';
import { AuthenticationService } from '../../login/shared/authentication.services';
import { PasswordCheckService } from '../../login/shared/password-check.service';
import { UpdatePasswordComponent } from '../update-password/update-password.component';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'update-password-manager',
  templateUrl: './update-password-manager.component.pug',
  styleUrls: ['./update-password-manager.component.scss']
})
export class UpdatePasswordManagerComponent implements OnInit {
  @ViewChild(UpdatePasswordComponent) updatePasswordComponent;
  updatePasswordClicked: boolean = false;
  registerForm: FormGroup;
  submitted: boolean = false;
  success: boolean = false;
  credentialsError: boolean = false;
  passwordStrengthError: boolean = false;
  user: User = new User();
  messageConfirmation: string;
  private currentUser;


  constructor(private formBuilder: FormBuilder,
      private userService: UsersService,
      private router: Router,
      private passwordCheckService: PasswordCheckService,
      private authenticationService: AuthenticationService,
      private toastr: ToastrService,
      private translateService: TranslateService
  ) { }
  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('CURRENT_USER'));
    this.registerForm = this.formBuilder.group({
        oldPassword: ['', Validators.required],
        newPassword: ['', [Validators.required, Validators.minLength(6)]],
        confirmPassword: ['', Validators.required]
    }, {
        validator: MustMatch('newPassword', 'confirmPassword')
    });
    this.translateService.get('update-password-manager.thanks').subscribe(message => this.messageConfirmation = message)
    

}

get f() { return this.registerForm.controls; }


checkFormValidation(): boolean {
    this.passwordStrengthError = false;
    this.submitted = true;
    if (this.user && this.user.newPassword) {
      if (this.passwordCheckService.checkPwdStrength(this.user.newPassword)) {
        return !this.registerForm.invalid;
      }
      this.passwordStrengthError = true;
      return false;
    }
    return false;
  }
  /** This function updates manager's password while a button is clicked
   * modify a property value
   */
  Cancel(): void {
    this.updatePasswordClicked = !this.updatePasswordClicked;
    this.router.navigate(['/profile']);
  }

/**
 * The function update the manager's password and use the validor for checking the oldpassword and the newpassword
 * Uses the authenticationService's method to change the password
 */
Update() {
        this.submitted = true;
         if (!this.checkFormValidation()) {
             return;
             
          }
          let user = {
            email: this.currentUser.email,
            oldPwd: this.user.oldPassword,
            newPwd: this.user.newPassword
          };
          
          this.authenticationService.changePassword(user).subscribe(
            () => {
              this.userService
                .removeTokenFromEmployeeByEmail(this.currentUser.email)
                .subscribe(() => {
                  this.success = true;
                  this.toastr.success(this.messageConfirmation);
                });
                
                this.router.navigate(['/profile']);
            },
            err => console.error('error', err)
          );
    }

}
