import { Injectable } from '@angular/core';
import { Result } from './result';
@Injectable({
  providedIn: 'root'
})
export class ResultService {
  getResult(): Promise<Result[]> {
    return Promise.resolve([
      { 
        name: 'excellent',
        label: 'overview.results.guru',
        color: 'yellow',
        status: false,
        showValue:100,
        minValue: 95,
        maxValue: 100
       },
       {
        name: 'very good',
        label: 'overview.results.master',
        color: 'orange',
        status: false,
        showValue:95,
        minValue: 80,
        maxValue: 94
       },
       {
        name: 'good',
        label: 'overview.results.confirmed',
        color: 'green',
        status: false,
        showValue:80,
        minValue: 50,
        maxValue: 79
       },
       {
        name: 'low',
        label: 'overview.results.intermediary',
        color: 'pink',
        status: false,
        showValue:50,
        minValue: 20,
        maxValue: 49
       },
       {
        name: 'very low',
        label: 'overview.results.noobie',
        color: 'purple',
        status: false,
        showValue:20,
        minValue: 0,
        maxValue: 19
       }
    ])
  }
}
