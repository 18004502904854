import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-library-by-folder',
  templateUrl: './library-by-folder.component.pug',
  styleUrls: ['./library-by-folder.component.scss']
})
export class LibraryByFolderComponent implements OnInit {
  public actualLang: string = "";
  result;
  constructor(
    public router: Router
  ) { }

  ngOnInit() {
  }
  LibByFolder(event){
    this.router.navigate(['/categories', event]);
  }
  sendLangToTechnoSelection(lang):void {
    this.actualLang = lang;
  }
}
