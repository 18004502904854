import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ImageService {


  	/*
	This function for resize uploaded image return blob to set it as an src attribute in the preview part
	PS:JUST USE URL.createObjectURL(blob) in the component to create a URL for your blob
	*/
	public resizeImage(file: File, maxWidth: number, maxHeight: number): Promise<Blob> {
		return new Promise((resolve, reject) => {
			let image = new Image();
			image.src = URL.createObjectURL(file);
			image.onload = () => {
				let width = image.width;
				let height = image.height;
				if (width <= maxWidth && height <= maxHeight) {
					resolve(file);
				}
				let newWidth;
				let newHeight;

				if (width > height) {
					newHeight = height * (maxWidth / width);
					newWidth = maxWidth;
				} else {
					newWidth = width * (maxHeight / height);
					newHeight = maxHeight;
				}
				let canvas = document.createElement('canvas');
				canvas.width = newWidth;
				canvas.height = newHeight;
				let context = canvas.getContext('2d');
				context.drawImage(image, 0, 0, newWidth, newHeight);
				canvas.toBlob(resolve, file.type);
			};
			image.onerror = reject;
		});
}}
