import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from './user.model';
import { Candidate } from './candidate.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  /** Base URL to API */
  readonly apiBaseUrl = environment.apiUrl;
  /** URL to get all users */
  readonly getAllUsersUrl = `${this.apiBaseUrl}/employees/getAll`;
  readonly getUserByIdUrl = `${this.apiBaseUrl}/employees/getUserById/`;
  readonly getUserByEmailUrl = `${this.apiBaseUrl}/employees/getUserByEmail/`;
  readonly getOrCreateCandidateByEmailUrl = `${
    this.apiBaseUrl
  }/candidates/email/find-or-create/`;
  readonly sendTestInvitationUrl = `${
    this.apiBaseUrl
  }/candidates/sendTestInvitation`;
  /** URL to delete a user by his id employee */
  readonly deleteUserByIdUrl = `${this.apiBaseUrl}/employees/deleteEmployee`;
  readonly getEmployeeByTokenUrl = `${
    this.apiBaseUrl
  }/employees/identifyUserByToken`;
  readonly removeTokenFromEmployeeByEmailUrl = `${
    this.apiBaseUrl
  }/employees/removeResetPasswordToken`;
  readonly getImageProfileByEmailUrl = `${this.apiBaseUrl}/employees/eosApi/`;
  /** URL to add a new user */
  readonly postAddNewUser = `${this.apiBaseUrl}/employees`;
  /** URL to update an existing user */
  readonly putUpdateUser = `${this.apiBaseUrl}/employees/updateEmployee`;
  /** URL to resend an invitation to a user */
  readonly postResendInvitation = `${this.apiBaseUrl}/employees/resendInvitation`;

  constructor(private http: HttpClient) {}

  /**
   * @description Get all users from backend
   * @returns {Observable<User[]>} should contain an array of users
   */
  public getUsers(): Observable<User[]> {
    return this.http.get<User[]>(this.getAllUsersUrl);
  }

  getUserById(employeeId: string): Observable<any> {
    return this.http.get<User>(`${this.getUserByIdUrl}${employeeId}`);
  }

  getUserByEmail(email: string): Observable<any> {
    return this.http.get<User>(`${this.getUserByEmailUrl}${email}`);
  }

  getOrCreateCandidateByEmail(
    email: string,
    managerId: string
  ): Observable<any> {
    return this.http.get<User>(
      `${this.getOrCreateCandidateByEmailUrl}${email}/manager/${managerId}`
    );
  }

  sendTestInvitation(candidate: Candidate, htmlContent: any, title: any): any {
    let body: any = Object.assign(
      candidate,
      JSON.parse(htmlContent),
      JSON.parse(title)
    );

    return this.http.post<User>(
      this.sendTestInvitationUrl,
      JSON.stringify(body)
    );
  }

  getEmployeeByToken(token: any): Observable<User> {
    return this.http.post<User>(
      this.getEmployeeByTokenUrl,
      JSON.stringify({ token: token })
    );
  }

  removeTokenFromEmployeeByEmail(email: string): Observable<User> {
    return this.http.post<User>(
      this.removeTokenFromEmployeeByEmailUrl,
      JSON.stringify({ email: email })
    );
  }

  getImageProfileByEmail(email: string): Observable<any> {
    return this.http.get<any>(`${this.getImageProfileByEmailUrl}${email}`);
  }

  /**
   * @description Sends new user's information to backend
   * @param createOptions new user's information to add in database
   * @returns {Observable<User>} should contain the new user data
   */
  public createUser(createOptions: any): Observable<User> {
    return this.http.post<User>(this.postAddNewUser, createOptions);
  }
  
  /**
   * @description Updates existing user's information to backend
   * @param {User} updateOptions user's information to update in database
   * @returns {Observable<User>} should contain the updated user data
   */
  public updateUser(updateOptions: User): Observable<User> {
    return this.http.put<User>(this.putUpdateUser, updateOptions);
  }

  /**
   * @description Resends an invitation to an existing user
   * @param {User} user "email" key and "firstName" key are used in the email template in backend
   * @return {Observable<any>} should contain a json with "idEmployee" key
   */
  public resendInvitation(user: User): Observable<any> {
    return this.http.post(this.postResendInvitation, user);
  }

  /**
   * @description Deletes an existing user
   * @param {string} idEmployee target id employee
   * @returns {Observable<any>} should contain en empty body with status code 200
   */
  public deleteUserById(idEmployee: string): Observable<any> {
    return this.http.request('delete', this.deleteUserByIdUrl, { body: { idEmployee: idEmployee } });
  }

}
