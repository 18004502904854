import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../material.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { TestsContainerComponent } from './tests-container/tests-container.component';
import { MailTemplateComponent } from './tests-container/mail-template/mail-template.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { TechnoSelectionComponent } from '../tests/tests-container/techno-selection/techno-selection.component';
import { QuestionsLibraryModule } from '../questions-library/questions-library.module';

@NgModule({
  imports: [
    FormsModule,
    MaterialModule,
    SharedModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    QuestionsLibraryModule
  ],
  declarations: [
    TestsContainerComponent,
    MailTemplateComponent,
    TechnoSelectionComponent,
  ],
  providers: [TechnoSelectionComponent]
})
export class TestModule {}
