import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RolesService } from '../services/roles.service';

@Injectable({
    providedIn: 'root'
})
export class AdminRoleGuard implements CanActivate {

    constructor(private rolesService: RolesService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.rolesService.isAdmin()) {
            return true;
        } else {
            this.router.navigate(['/candidatures']);
            return false;
        }
    }
}