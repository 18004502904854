import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { QuestionService } from 'src/app/modules/questions-library/shared/question.service';
import { SubTechnology } from 'src/app/modules/questions-library/shared/subtechnology.model';
import { TechCategory } from 'src/app/modules/questions-library/shared/techCategory.model';
import { Technology } from "src/app/modules/questions-library/shared/technology.model";
import { Question } from "src/app/modules/questions-library/shared/question.model";
import { filterByAlphabetical } from 'src/app/shared/_helpers/functions';


@Component({
  selector: 'app-techno-selection',
  templateUrl: './techno-selection.component.pug',
  styleUrls: ['./techno-selection.component.scss']
})

export class TechnoSelectionComponent implements OnChanges {

  isChecked=false;
  checkedSubTech: string;
  subTechQuestionLimit = 8;
  isHidden=true;
  searchString: string = '';
  public section_name: string;
  techCategoriesList: TechCategory[] = [];
  techCategoriesListImportant: TechCategory[] = [];
  techCategoriesListNotImportant: TechCategory[] = [];
  technologiesList: Technology[] = [];
  bkpTechnologiesList: Technology[] = [];
  selectedSubTechnologies: string[] = [];
  dataSource: MatTableDataSource<TechCategory>;
  @Output() onCheckedSubtech: EventEmitter<any> = new EventEmitter<any>();
  @Input() actualLang = '';

  constructor(
    private questionService: QuestionService,
    public router: Router,
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.actualLang && changes.actualLang.currentValue && this.section_name) {
      this.getTechnologyById();
    }
  }

  getTechnologyById() {
    this.questionService
      .getTechnologyById(this.section_name)
      .subscribe(async (technologies: Technology[]) => {
        technologies = filterByAlphabetical(technologies)
        for await (let technology of technologies) {
          for await (let subTechnology of technology.subTechnologies) {
            try {
              await this.questionService
                .getQuestionsBySubtechnology(subTechnology.idSubTechnology)
                .toPromise()
                .then(
                  (questions: Question[]) =>
                    (subTechnology.questionLimit = questions.length)
                );
            } catch (error) {
              console.error(error);
            }
          }
        }
        this.technologiesList = technologies.filter((technology: Technology) =>
          technology.subTechnologies.some(
            (subTechnology: SubTechnology) =>
              subTechnology.questionLimit >= this.subTechQuestionLimit
          )
        );
        this.bkpTechnologiesList = this.technologiesList;
      });
    return this.technologiesList
  }

  GetCategoryName(event, el: HTMLElement) {
    el.scrollIntoView();
    this.section_name=event;
    this.getTechnologyById();
    this.isHidden = false;
  }

  isChosenSubTechnology(idSubTech: string) {
    const index = this.selectedSubTechnologies.indexOf(idSubTech);
    if (index === -1) {
    // Ajouter à la liste si non sélectionné
      this.selectedSubTechnologies.push(idSubTech);
    } else {
    // Retirer de la liste si déjà sélectionné
      this.selectedSubTechnologies.splice(index, 1);
    }
    this.onCheckedSubtech.emit(this.selectedSubTechnologies);
  }

  getSearchFilterValue(event) {
    this.searchString = event;
    this.bkpTechnologiesList = this.getTechno(this.bkpTechnologiesList);

    this.bkpTechnologiesList = this.bkpTechnologiesList.filter(techno => techno.subTechnologies.length > 0);
  }

  getTechno(technologiesList) {
    if (this.searchString.length > 0) {
      return technologiesList.map(techno => {
        return {
          ...techno,
          subTechnologies: techno.subTechnologies.filter(sub => sub.name.toLowerCase().includes(this.searchString.toLowerCase()))
        }
      })
    } else {
      return this.technologiesList;
    }
  }

  deleteTabSubTech(tab) {
    tab.subTechnologies.length = 0
  }
  addTabSubTech(tab, tabToAdd) {
    tab.subTechnologiesTechnology = tabToAdd
  }
  isSelectedSubTechnology(idSubTech: string): boolean {
    return this.selectedSubTechnologies.includes(idSubTech);
  }
}
