import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Candidate } from './candidate.model';
import { EmailTitle, EmailBody, EmailResponse } from './email.model';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TestsService {

	/** URL to Appskills API */
	private readonly apiBaseUrl = environment.apiUrl;

	constructor(
	private http: HttpClient
	) {}
  
	/**
	 * @description Sends test invitation to a candidate
	 * @param {Candidate} candidate candidate data
	 * @param {EmailBody} htmlContent html content to be displayed in email
	 * @param {EmailTitle} title title to be displayed in email
	 * @returns {Observable<any>} should contain a json data with info key when succeed
	 */
	public sendTestInvitation(idSubTech:string,candidate: Candidate, htmlContent: EmailBody, title: EmailTitle, testLongValidity: boolean, testID:string): Observable<EmailResponse> {
		
		const body: any = Object.assign(candidate, htmlContent, {title:title.title,testLongValidity,testID});
		return this.http.post<EmailResponse>(this.apiBaseUrl + `/candidates/sendTestInvitation/${idSubTech}`, JSON.stringify(body));
	}

	/**
	 * @description Gets or creates (if candidate does not exist) candidate by sending email's candidate in params
	 * @param {string} email email's candidate
	 * @param {string} managerId manager's id
	 * @returns {Observable<Candidate>} should contain a json data with Candidate model
	 */
	public getOrCreateCandidateByEmail(email: string, managerId: string, choosenSubTech): Observable<Candidate> {
		const numberOfChoosenSubTech=choosenSubTech.length
		return this.http.get<Candidate>(this.apiBaseUrl + `/candidates/email/find-or-create/${email}/manager/${managerId}/choosenSubTech/${numberOfChoosenSubTech}`);
	}

}
