import { Answer } from './answer.model';
import { QuestionL } from './questionlevel.model';
 
export class Question{
    idquestion: string;
    question: string;
    title: string;
    priority: number;
    time: number;
    help: string;
    type: string;
    employeeId: string;
    answers: Answer[];
    subTechnologyId: string;
    file: File;
    question_picture_url: string
    levels: QuestionL[];
}
