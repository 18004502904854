import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild
} from '@angular/core';
import { MatDialog, MatInput } from '@angular/material';
import { FiltersService } from '../shared/filters.service';
import { CandidaturesResultDialogComponent } from '../candidatures-dialog/candidatures-result-dialog/candidatures-result-dialog.component';
import { CandidaturesDialog } from '../candidatures-dialog/candidatures-dialog.component';
import { CandidaturesService } from '../shared/candidatures.service';
import { Candidature } from '../shared/candidature';
import { Result } from '../shared/result';
import { ResultService } from '../shared/result.service';

export class DialogData {
  chosenFilters: string[];
}

@Component({
  selector: 'app-candidatures-synthesis',
  templateUrl: './candidatures-synthesis.component.pug',
  styleUrls: ['./candidatures-synthesis.component.scss']
})

export class CandidaturesSynthesisComponent implements OnInit {
  @ViewChild('inputStartDate', { read: MatInput}) inputStartDate: MatInput;
  @ViewChild('inputEndDate', { read: MatInput}) inputEndDate: MatInput;
  @Output() static triggerFilters: EventEmitter<any> = new EventEmitter<any>();
  searchString: string = '';
  startDate: Date = null;
  endDate: Date = null;
  maxDate: Date = new Date();
  result: string;
  initialList: Candidature[] = [];
  displayedTestList: Candidature[] = [];
  chosenSubTechnologies: string[];
  chosenResults : Result[] = [];
  resultInit: Result[];
 
  constructor(
    public dialog: MatDialog,
    public filterService: FiltersService,
    private candidatureService: CandidaturesService,
    private resultService: ResultService,
    ) { }

  ngOnInit() {
    this.resultService.getResult().then(results => {
      this.resultInit = results;
    });
    this.candidatureService.getCandidatures().subscribe(candidatures => {
      this.initialList = candidatures;
      this.displayedTestList = this.initialList;
      CandidaturesSynthesisComponent.triggerFilters.emit(this.initialList);
    });
  }
  
  openDialog(dialogName: string) {
    let dialog;
    let width;

    switch (dialogName) {
      case 'techno':
        dialog = CandidaturesDialog;
        width = '70%';
        this.dialog.open(dialog, {
          width: width,
          data: {
            chosenFilters: this.chosenSubTechnologies
          },
          panelClass: 'myapp-no-border-radius-dialog'
        }).afterClosed().subscribe(result => {
          if (result) {
            this.chosenSubTechnologies = result;
          }
          this.applyAllFilters();
        });
        break;
      case 'result':
        dialog = CandidaturesResultDialogComponent;
        width = '35%';
        this.dialog.open(dialog, {
          width: width,
          data: {
            chosenFilters: this.chosenResults
          },
          panelClass: 'myapp-no-border-radius-dialog'
        }).afterClosed().subscribe(result => {
          if (result) {
            this.chosenResults = result;
          }
          this.applyAllFilters();
        });
        break;
      default:
        break;
    }
  }

  /**
  * This function use 3 differents filtre to finally emit the candidatures list with chosen filters
  * @param initialList The initial candidatures list
  * @param displayedTestList The updated candidatures list 
  * @param triggerFilters The event emitter
  */
  applyAllFilters() {
    this.displayedTestList = this.initialList;
    this.displayedTestList = this.applySubTechnologyFilter(this.chosenSubTechnologies);
    this.displayedTestList = this.applyResultFilter(this.chosenResults);
    this.displayedTestList = this.applyDatesFilter(this.startDate, this.endDate);

    CandidaturesSynthesisComponent.triggerFilters.emit(this.displayedTestList);
  }

  /**
 * This function apply the chosen filters in a candidatures list and return a list updated if there are filters
 * @param results chosen filters
 * @param displayedTestList The candidatures list
 * @param candidatureList The updated candidatures list
 */
  applyResultFilter(results: Result[]) : Array<Candidature>{
    let candidatureList: Array<Candidature> = [];
    if (results && results.length > 0) {
      results.forEach(result => {
        this.displayedTestList.forEach(candidature => {
          if (candidature.finalScore >= result.minValue && candidature.finalScore <= result.maxValue) {
            candidatureList.push(candidature);
          }
        });
      })
      return candidatureList;
    }
    return this.displayedTestList;
  }

  /**
 * This function apply the chosen filters in a candidatures list and return a list updated if there are filters
 * @param technologiesFilter chosen filters
 * @param displayedTestList The candidatures list
 * @param candidatureList The updated candidatures list
 */
  applySubTechnologyFilter(technologiesFilter: string[]): Array<Candidature> {
    let candidatureList: Array<Candidature> = [];
    if (technologiesFilter && technologiesFilter.length > 0) {
      this.displayedTestList.forEach(candidature => {
        if (technologiesFilter.indexOf(candidature.subTechName) !== -1) {
          candidatureList.push(candidature);
        }
      })
      return candidatureList;
    }
    return this.displayedTestList;
  }

  /**
   * This function apply the date filter and return the list of filtred candidatures.
   * @param searchDates start and end dates
   */
  applyDatesFilter(startDate: Date, endDate: Date): Array<Candidature>{
    if( startDate === null && endDate === null)
      return this.displayedTestList;
    
    let candidatureList: Array<Candidature> = [];
    if (startDate !== null || endDate !== null) {
      this.displayedTestList.forEach(candidature => {
        if(startDate !== null && endDate === null) {
          if( Number(new Date(candidature.testStartDate).valueOf()) >= Number(startDate.valueOf()) )
            candidatureList.push(candidature);
        }else if(endDate !== null && startDate === null){          
          if( Number(new Date(candidature.testStartDate).valueOf()) <= Number(endDate.valueOf()) )
            candidatureList.push(candidature);
        }else {
          if( (Number(new Date(candidature.testStartDate).valueOf()) >= Number(startDate.valueOf())) && (Number(new Date(candidature.testStartDate).valueOf()) <= Number(endDate.valueOf())) )
          candidatureList.push(candidature);
        }
      });
      return candidatureList;
    }
  }

  /**
 * This function removes an element of the chosen SubTechnologies array
 * @param subTechnology The selected filter to remove
 * @param chosenSubTechnologies The selected filter to remove
 * @returns {subTechnology, Array} The updated SubTechnologies array
 * @function applyAllFilters() update the list of candidatures
 */
  removeSubTechnology(subTechnology: string): void {
    this.chosenSubTechnologies = this.filterService.removeFilter(
      subTechnology,
      this.chosenSubTechnologies
    );
    this.applyAllFilters();
  }

  /**
 * This function removes an element of the chosen Results array
 * @param result The selected filter to remove
 * @param chosenResults The selected filter to remove
 * @returns {result, Array} The updated Results array
 * @function applyAllFilters() update the list of candidatures
 */
  removeResult(result: any): void {
    this.chosenResults = this.filterService.removeFilter(
      result,
      this.chosenResults
    );
    this.applyAllFilters();
  }

  /**
   * This functions listen the child app-search-filter string value and set it to local searchString
   * @param event The string value
   */
  getSearchFilterValue(event) {
    this.searchString = event;    
  }

  /**
   * TODO function handling start date filters
   * @param event User start date input
   */
  startDateOnChange(event) {
    this.startDate = event.value;
    this.applyAllFilters();
   }

  /**
   * TODO function handling end date filters
   * @param event User end date input
   */
  endDateOnChange(event) { 
    this.endDate = event.value;
    this.applyAllFilters();
  }

  resetStartDate() {
    this.inputStartDate.value = '';
    this.startDate = null;
    this.applyAllFilters();
  }

  resetEndDate() {  
    this.inputEndDate.value = '';
    this.endDate = null;
    this.applyAllFilters();
  }
}
