import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.pug'
})
export class AppComponent implements OnInit {

  title = 'app-skills-v2';
  constructor(public translate: TranslateService, private metaService: Meta) {
    // translate.addLangs(['en', 'fr']);
    translate.addLangs(['fr']);
    translate.setDefaultLang('fr');
  }
  ngOnInit() {
    this.metaService.addTags([
      {name: 'description', content: 'Appskills c’est bien plus qu’un outil de QCMs en ligne !\n' +
          'En effet, nos candidats pourront s’orienter vers les postes qui matchent le plus avec leurs niveaux de compétences et accélérer leur processus de recrutement ! Nos consultants pourront s’y entrainer et ' +
          's’autoévaluer pour franchir différents paliers et niveaux ! Nos clients pourront partager avec nous leurs attentes, customiser les questionnaires, objectiver nos compétences (« puisqu’on vous dit qu’il est ' +
          'hallucinamment bon pour son âge ! » ?) comparer la performance de nos équipes et … des leurs ! (Chiche !).'},
    ]);
  }
}
