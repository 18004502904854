import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { CustomErrorStateMatcher } from 'src/app/shared/models/custom-error-state-matcher';
import { Inject } from '@angular/core';
import { User } from '../../shared/user.model';

@Component({
  selector: 'app-update-user-dialog',
  templateUrl: './update-user-dialog.component.pug',
  styleUrls: ['./update-user-dialog.component.scss']
})
export class UpdateUserDialogComponent implements OnInit {

  /** The form */
  public form: FormGroup;
  /** errorMatcher used for formControls */
  public errorMatcher = new CustomErrorStateMatcher();
  /** The index for the user's role */
  public targetRoleIndex: number = 0;
  /** Roles to display for the checkbox */
  public roleList: any[] = [
    {
      label: "users.roles.lecture",
      role: "lecture",
      isChecked: true
    },
    {
      label: "users.roles.manager",
      role: "manager",
      isChecked: false
    },
    {
      label: "users.roles.admin",
      role: "admin",
      isChecked: false
    }
  ];

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<UpdateUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: User
  ) { }

  ngOnInit() {
    // Defines rules for the defined formControl
    this.form = this.fb.group({
      lastName: [this.data.lastName, Validators.required],
      firstName: [this.data.firstName , Validators.required],
      email: [this.data.email, [Validators.required, Validators.email]],
      role: [this.data.role, [Validators.required]] // default role will be lecture
    });

    // Selects role for the checkbox
    for (let i=0; i < this.roleList.length; i++) {
      if (this.roleList[i].role === this.data.role) {
        this.roleList[i].isChecked = true;
      } else {
        this.roleList[i].isChecked = false;
      }
    }
  }

  /**
   * @description Sends data if form is valid
   */
  public onSubmit(): void {
    if (this.form.valid) {
      this.dialogRef.close(this.form.value);
    }
  }

  /**
   * @description Closes modal
   */
  public onClose(): void {
    this.dialogRef.close();
  }

  /**
   * @description Handles click selection for the user's role selection. Only one role should be selected
   * @param {number} event Event triggered by the DOM; should contain target index
   */
  public onClickSelection(event: number): void {
 
    for (let i = 0; i < this.roleList.length; i++) {
      if (event === i) {
        this.roleList[i].isChecked = true;
        this.form.controls['role'].setValue(this.roleList[i].role);
      } else {
        this.roleList[i].isChecked = false;
      }
    }
  }

}
