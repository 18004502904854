import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Technology } from '../shared/technology';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TechnologyService {

  readonly apiBaseUrl = environment.apiUrl;
  readonly getTechnologiesUrl = `${this.apiBaseUrl}/technologies/subtechnologies/list`;

  constructor(private http: HttpClient) { }

  getTechnologiesWithSubtechnologies(): Observable<Technology[]> {
    return this.http.get<Technology[]>(`${this.getTechnologiesUrl}`);
  }

  createTechnology(technology: Technology) {
    let addTechnology = this.http.post(`${this.apiBaseUrl}/technologies/`, technology);
    return addTechnology;
  }
}
