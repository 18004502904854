export class Candidature {
    id: string;
    firstName: string;
    lastName: string;
    testDate: Date;
    email: string;
    phone: string;
    testedBy: string;
    dybLink: string;
    technoDetails: Array<TechnoDetail>;
    duration: string;
    finalScore: number;
    candidateComment: string;
    managerFirstName: string;
    managerLastName: string;
}

class TechnoDetail {
    name : string; 
    score: number;
}