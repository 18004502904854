import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { AuthGuard } from 'src/app/shared/_guards/auth.guard';
import { UpdatePasswordComponent } from './update-password/update-password.component';
import { UsersService } from '../users/shared/users.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatPaginatorModule } from '@angular/material/paginator';


import {
  MatTableModule,
  MatChipsModule,
  MatButtonModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  MatSnackBarModule
} from '@angular/material';
import { UpdatePasswordManagerComponent } from './update-password-manager/update-password-manager.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { ManagerCandidaturesComponent } from './manager-candidatures/manager-candidatures.component';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule } from '@angular/common/http';
import { myProfileComponents } from './my-profile.routes';

@NgModule({
  declarations: [myProfileComponents],
  imports: [
    HttpClientModule,
    MatPaginatorModule,
    MatSnackBarModule,
    MatTableModule,
    MatChipsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatSelectModule,
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    BrowserModule,
	  BrowserAnimationsModule,
	  ToastrModule.forRoot()
  ],
  exports: [MyProfileComponent],
  providers: [UsersService, AuthGuard, HttpClientModule],
  
  entryComponents : [UpdatePasswordComponent, UpdatePasswordManagerComponent]
})
export class MyProfileModule { }
