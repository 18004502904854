import { Component, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TechnologyService } from '../shared/technology.service';
import { Technology } from '../shared/technology';
import { FiltersService } from '../shared/filters.service';

@Component({
  selector: 'candidatures-dialog',
  templateUrl: './candidatures-dialog.component.pug',
  styleUrls: ['./candidatures-dialog.component.scss']
})
export class CandidaturesDialog {
  isLoading: boolean = true;
  technos: Array<Technology>;
  backupTechnos : Array<Technology> = [];
  chosenSubTechnologies: Array<string>;
  searchString: string = '';
  @Output() static triggerFilters: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private technologyService: TechnologyService,
    public dialogRef: MatDialogRef<CandidaturesDialog>,
    public filterService: FiltersService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit():void {    
    this.chosenSubTechnologies = [];
    this.technologyService.getTechnologiesWithSubtechnologies().subscribe(
      (technos: Array<Technology>) => {
        this.technos = this.technosFilter(technos);
        this.backupTechnos = this.technos;
        this.isLoading = false;
      }
    );

    if (this.data.chosenFilters && this.data.chosenFilters.length > 0) {            
      this.data.chosenFilters.forEach(subTechnologies => {
        this.chosenSubTechnologies.push(subTechnologies);
      });
    }    
  }

    /**
  * This function call the filterService to add subTechnology in a subTechnology array if he is already not
  * @param subTechnology the subTechnology chosen by the user
  * @param chosenLevels subTechnologies array chosen by the user
  */
  addFilter(subTechnology: string) {
    !this.filterService.checkExistingFilter(subTechnology, this.chosenSubTechnologies)
    ? this.chosenSubTechnologies.push(subTechnology)
    : this.removeSubTechnology(subTechnology);
  }

  /**
   * This function allows you to select technologies whose name is not null and 
   * which have at least one sub-technology
   * @returns technos technologies array
   */
  technosFilter(technos): Array<Technology> {
    return technos.filter(techno => techno.name != null 
      && techno.subTechnologies[0]!= null);
  }

  /**
  * This function remove subTechnology if he is already in chosenSubTechnologies
  * @param subTechnology the subTechnology chosen by the user
  * @param chosenSubTechnologies The updated levels array
  */
 removeSubTechnology(subTechnology): void {
    this.chosenSubTechnologies = this.filterService.removeFilter(
      subTechnology,
      this.chosenSubTechnologies
    );
  }

  /**
  * This function check if a subTechnology is in the SubTechnologies array and return a boolean
  * @param subTechnology a subTechnology 
  * @param chosenLevels The SubTechnologies array
  */
  isChosenSubTechnology(subTechnology: string): boolean {    
    return this.chosenSubTechnologies.some(chosenSubTechnology => {
      return chosenSubTechnology === subTechnology;
    });
  }

  /**
  * This function emit the SubTechnologies array & close the subTechnology dialog
  * @param triggerFilters event use to emit data
  * @param chosenSubTechnologies SubTechnologies array 
  */
  validate(): void {
    this.dialogRef.close(this.chosenSubTechnologies);
  }

  getSearchFilterValue(event) {
    this.searchString = event;
    this.technos = this.getTechno(this.technos);
    this.technos = this.technos.filter(techno => techno.subTechnologies.length > 0);
  }

  getTechno(technologiesList) {
    if (this.searchString.length > 0) {
      return technologiesList.map(techno => {
        return {
          ...techno,
          subTechnologies: techno.subTechnologies.filter(sub => sub.name.toLowerCase().includes(this.searchString.toLowerCase()))
        }
      })
    } else {
      this.technos = this.backupTechnos;
      return this.technos;
    }
  }
}
