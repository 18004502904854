import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators, FormArray } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { SubTechnologyService } from "../sub-technology.service";
import { SubTechnology } from "src/app/modules/candidatures/shared/subtechnology";
@Component({
  selector: "app-technologies-library",
  templateUrl: "./subTechnology-library.component.pug",
  styleUrls: ["./subTechnology-library.component.scss"],
})
export class SubTechnologyLibraryComponent implements OnInit {
  subTechnologyForm: FormGroup;
  isValideForm = false;
  subTechnology: SubTechnology = new SubTechnology();
  isValid: any = {
    settedTitle: true,
  };
  submitted = false;

  private idCategory: string;

  private idTechnology: string;

  constructor(
    public router: Router,
    public route: ActivatedRoute,

    private toastr: ToastrService,
    private subTechnologyService: SubTechnologyService,
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
    this.idCategory = this.route.snapshot.params['section_name'];
    this.idTechnology = localStorage.getItem('idTechnology')
    this.initForm()
  }

  initForm() {
    this.subTechnologyForm = this.fb.group({
      idTechnology: null,
      title: ["", Validators.required]
    });
  }

  onEdit(idCategory) {
    this.subTechnology = {
      idSubTechnology: "",
      name: this.subTechnologyForm.value.title,
      technologyId: this.idTechnology
    }
    this.subTechnologyService.createSubTechno(this.subTechnology).subscribe(
      (data :SubTechnology) => {
        this.router.navigate([`/categories/${idCategory}/subtechnology/${data.idSubTechnology}`]);
        this.toastr.success(`Sous-Technologie ${data.name} crée`)
      },
      (err) => {
        this.toastr.error("Erreur lors de la création de la technologie");
      }
    )
  }

  validateInput() {
    this.subTechnologyForm.value.title.length == 0 ? this.isValid.settedTitle = false : this.isValid.settedTitle = true;
    if (this.isValid.settedTitle) this.isValideForm = true;
  }

  onSubmit() {
    this.submitted = true;
    this.validateInput();

    if (this.isValideForm) {
      this.onEdit(this.idCategory)
    } else {
      this.toastr.error("Tu dois valider tous les champs");
    }
  }

}
