import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs';

import { TechCategory } from '../../shared/techCategory.model';
import { QuestionService } from '../../shared/question.service';


@Component({
  selector: 'app-categories-box',
  templateUrl: './categories-box.component.pug',
  styleUrls: ['./categories-box.component.scss']
})
export class CategoriesBoxComponent implements OnInit {

  techCategoriesList: TechCategory[] = [];
  techCategoriesListImportant: TechCategory[] = [];
  techCategoriesListNotImportant: TechCategory[] = [];
  dataSource: MatTableDataSource<TechCategory>;
  url: string;
  paramsSubscription: Subscription;


  constructor(
    private questionService: QuestionService,
    public router: Router
  ) { }

  ngOnInit() {
    this.url = this.router.url;
    this.getTechCategories();
   
  }

  getTechCategories() {
    this.questionService.getTechCategories().subscribe(categs => {
      this.techCategoriesList = categs;
      this.dataSource = new MatTableDataSource(this.techCategoriesList);
      this.techCategoriesList.forEach((category) =>
        category.important
      ? this.techCategoriesListImportant.push(category)
      : this.techCategoriesListNotImportant.push(category)
      );
    }, err => console.error(err));
  }

  LibByFolder(sectionName: any) {
    this.router.navigate(['/categories', sectionName]); 
  }
}
