import { Component, OnInit } from '@angular/core';
import { UsersService } from '../../users/shared/users.service';
import { CurrentUser } from 'src/app/shared/models/currentUser';
import { UpdatePasswordManagerComponent } from '../update-password-manager/update-password-manager.component';
import { Router } from '@angular/router';

@Component({
  selector: 'update-password',
  templateUrl: './update-password.component.pug',
  styleUrls: ['./update-password.component.scss']
})
export class UpdatePasswordComponent implements OnInit {
  updatePasswordClicked: boolean = false;
  profilePicture;
  url: string;
  currentUser: CurrentUser;
  profileName;
  branchName;
  phoneNumber;

  constructor(private usersService: UsersService, private router: Router) {}

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('CURRENT_USER'));
    if (this.currentUser != null) {
      this.getImageProfile(this.currentUser.email);
    }
   this.url = this.router.url;
  }

  /** This function updates manager's password while a button is clicked
   * modify a property value
   */
  updatePasswordManager(): void {
    this.updatePasswordClicked = !this.updatePasswordClicked;
    this.router.navigate(['/profile/update-password-manager']);
   
  }

  /**
   * This function gets the user's Image by calling the UsersService's method which take the email as param
   * @returns an url of the profilePicture from the eos
   * @returns the profileName = the profession (manager, consultant, ..)
   * @returns the branchName = (Davidson SI, technologies, ..)
   * @returns the phoneNumber = (06..,07.., ..)
   */
  getImageProfile(email: string) {
    this.usersService.getImageProfileByEmail(email).subscribe(result => {      
      this.profilePicture = result.avatar_path;
      this.profileName = result.profileName;
      this.branchName = result.branchName;
      this.phoneNumber = result.phoneNumber;
    });
  }

}
