import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LevelService {

  readonly apiBaseUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }


  /**
* This function if a element already exist in a element array 
* @param element element choose by the user
* @param elementArray array containing elements already chose before
* @return boolean
*/
  checkExistingFilter(element, elementArray): boolean {
    let bool: boolean = false;
    elementArray.forEach(e => {
      if (e === element) {
        bool = true;
      }
    });

    return bool;
  }

  /**
  * This function if a element already exist in a element array 
  * @param element element choose by the user
  * @param elementArray array containing elements already chose before
  * @return new elementArray
  */
  removeFilter(element, elementArray): Array<any> {
    elementArray.forEach(e => {
      if (e === element) {
        elementArray.splice(elementArray.indexOf(element), 1)
      }
    });
    return elementArray;
  }
}
