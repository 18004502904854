import {
  Component,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'app-search-filter',
  templateUrl: './search-filter.component.pug',
  styleUrls: ['./search-filter.component.scss']
})
export class SearchFilterComponent {
  @Output() searchFilter: EventEmitter<string> = new EventEmitter<string>();
  searchString = '';
  oldSearchString = '';
  
  /**
   * This function is a custom ngOnChanges : it checks when searchString is updated and emit its value to the output filter event
   */
  ngDoCheck() {
    if (this.searchString !== this.oldSearchString) {
      this.oldSearchString = this.searchString;
      this.searchFilter.emit(this.searchString);
    }
  }
}
