import {Component, OnInit, Output, EventEmitter, Input, SimpleChanges, OnChanges} from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { QuestionService } from 'src/app/modules/questions-library/shared/question.service';
import { TechCategory } from 'src/app/modules/questions-library/shared/techCategory.model';
import { Technology } from 'src/app/modules/questions-library/shared/technology.model';


@Component({
  selector: 'app-list-categories',
  templateUrl: './list-categories.component.pug',
  styleUrls: ['./list-categories.component.scss'],
})
export class ListCategoriesComponent implements OnInit, OnChanges {
  @Output() getTechnoQuestionLibrary: EventEmitter<any> = new EventEmitter();
  @Input()  actualLang = '';
  ngOnChanges(changes: SimpleChanges) {
    if (changes.actualLang && changes.actualLang.currentValue) {
      this.getTechCategories();
    }
  }
  managementImgDefault: string = "../../../../assets/images/mgt-projets.png";
  managementImgHover: string = "../../../../assets/images/mgt-projets-vert.png";
  managementImgSource: string;
  itDigitalImgDefault: string = "../../../../assets/images/it-digital.png";
  itDigitalImgHover: string = "../../../../assets/images/it-digital-vert.png";
  itDigitalImgSource: string;
  telecomsImgDefault: string = "../../../../assets/images/telecoms.png";
  telecomsImgHover: string = "../../../../assets/images/telecoms-vert.png";
  telecomsImgSource: string;
  indusImgDefault: string = "../../../../assets/images/indus.png";
  indusImgHover: string = "../../../../assets/images/indus-vert.png";
  indusImgSource: string;
  clientsImgDefault: string = "../../../../assets/images/clients.png";
  clientsImgHover: string = "../../../../assets/images/clients-vert.png";
  clientsImgSource: string;
  listTechno: string;
  url: string;
  sectionName: any;
  techCategoriesList: TechCategory[] = [];
  techCategoriesListImportant: TechCategory[] = [];
  techCategoriesListNotImportant: TechCategory[] = [];
  technologiesList: Technology[] = [];
  dataSource: MatTableDataSource<TechCategory>;
  constructor(
    private questionService: QuestionService,
    public router: Router) { }

  ngOnInit() {
    this.initialSources();
    this.url = this.router.url;
    this.getTechCategories();
  }

  getTechCategories() {
    this.questionService.getTechCategories().subscribe(categs => {
      this.techCategoriesList = categs;
      this.dataSource = new MatTableDataSource(this.techCategoriesList);
      this.techCategoriesListImportant = [];
      this.techCategoriesListNotImportant = [];
      this.techCategoriesList.forEach((category) =>
        category.important
      ? this.techCategoriesListImportant.push(category)
      : this.techCategoriesListNotImportant.push(category)
      );

    }, err => console.error(err));
  }

  goToTechnologies(sectionName){
    this.getTechnoQuestionLibrary.emit(sectionName);
  }
  /**
   * This function initial all image souces of each category
   */
  initialSources(){
    this.managementImgSource = this.managementImgDefault;
    this.itDigitalImgSource = this.itDigitalImgDefault;
    this.telecomsImgSource = this.telecomsImgDefault;
    this.indusImgSource = this.indusImgDefault;
    this.clientsImgSource = this.clientsImgDefault;
  }
  /**
   * This function handles the categories images sources
   * @param category {String} the category
   * @param value {Boolean} if the mouse is inside (true) or outside (false) the div
   */
  handleImageSrc(category, value) {
    switch (category) {
      case "MANAGEMENT":
        this.managementImgSource = value
          ? this.managementImgHover
          : this.managementImgDefault;
          break;
      case "IT DIGITAL":
        this.itDigitalImgSource = value
          ? this.itDigitalImgHover
          : this.itDigitalImgDefault;
          break;
      case "TELECOMS":
        this.telecomsImgSource = value
          ? this.telecomsImgHover
          : this.telecomsImgDefault;
          break;
      case "INDUSTRIE":
        this.indusImgSource = value
          ? this.indusImgHover
          : this.indusImgDefault;
          break;
      case "CLIENTS":
        this.clientsImgSource = value
          ? this.clientsImgHover
          : this.clientsImgDefault;
          break;
    }
  }
}
