import { Injectable } from '@angular/core';

export const enum PasswordCheckStrength {
  Short,
  Common,
  Weak,
  Ok,
  Strong
}

@Injectable({
  providedIn: 'root'
})
export class PasswordCheckService {
  static get MinimumLength(): number {
    return 10;
  }

  private commonPasswordPatterns: any = /passw.*|12345.*|09876.*|qwert.*|asdfg.*|zxcvb.*|footb.*|baseb.*|drago.*/;

  isPasswordCommon(password: string): boolean {
    return this.commonPasswordPatterns.test(password);
  }

  checkPasswordStrength(password: string): PasswordCheckStrength {
    let numberOfElements = 0;
    numberOfElements = /[a-z]/.test(password) ? ++numberOfElements : numberOfElements; // Lowercase
    numberOfElements = /[A-Z]/.test(password) ? ++numberOfElements : numberOfElements; // Uppercase
    numberOfElements = /[0-9]/.test(password) ? ++numberOfElements : numberOfElements; // Numbers
    numberOfElements = /[^a-zA-Z0-9]/.test(password) ? ++numberOfElements : numberOfElements; // Special characters

    let currentPasswordStrength: PasswordCheckStrength =
      PasswordCheckStrength.Short;

    if (
      password === null ||
      password.length < PasswordCheckService.MinimumLength
    ) {
      currentPasswordStrength = PasswordCheckStrength.Short;
    } else if (this.isPasswordCommon(password) === true) {
      currentPasswordStrength = PasswordCheckStrength.Common;
    } else if (
      numberOfElements === 0 ||
      numberOfElements === 1 ||
      numberOfElements === 2
    ) {
      currentPasswordStrength = PasswordCheckStrength.Weak;
    } else if (numberOfElements === 3) {
      currentPasswordStrength = PasswordCheckStrength.Ok;
    } else {
      currentPasswordStrength = PasswordCheckStrength.Strong;
    }

    return currentPasswordStrength;
  }

  checkPwdEquals(passwords: any): boolean {
    if (passwords.password === passwords.confirmPassword) {
      return true;
    } else {
      return false;
    }
  }

  checkPwdStrength(password: string): boolean {
    if (this.checkPasswordStrength(password) < 3) {
      return false;
    } else {
      return true;
    }
  }
}
