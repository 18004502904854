import { Component, ViewChild, OnInit } from '@angular/core';
import { UpdatePasswordComponent } from '../update-password/update-password.component';
import { CurrentUser } from '../../../shared/models/currentUser';
import { UpdatePasswordManagerComponent } from '../update-password-manager/update-password-manager.component';
import { Router } from '@angular/router';

@Component({

  templateUrl: './my-profile.component.pug',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit{
  currentUser: CurrentUser;
  updatePasswordClicked: boolean = false;
  profilePicture;

  @ViewChild(UpdatePasswordComponent) updatePasswordComponent;
  @ViewChild(UpdatePasswordManagerComponent) updatePasswordManagerComponent;
  constructor(private router: Router) { }
  ngOnInit(): void {
    this.currentUser = new CurrentUser();
    this.currentUser = JSON.parse(localStorage.getItem('CURRENT_USER'));
    
  }
  /** This function updates manager's password while a button is clicked
   * modify a property value
   */
  updatePasswordManager(): void {
    this.updatePasswordClicked = !this.updatePasswordClicked;
    this.router.navigate(['/profile/update-password-manager']);
  }
}
