import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../environments/environment";

import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

import { User } from "../shared/user.model";
import { CookieService } from "ngx-cookie-service";

import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  rememberMe: boolean;
  redirectUrl: string;
  readonly apiBaseUrl = environment.apiUrl;
  readonly loginUrl: string = `${this.apiBaseUrl}/employees/login`;
  readonly resetPasswordUrl = `${this.apiBaseUrl}/employees/resetPassword`;
  readonly changePasswordUrl = `${this.apiBaseUrl}/employees/changePwd`;
  readonly sendResetPasswordEmailUrl = `${this.apiBaseUrl}/employees/sendResetPasswordEmail`;

  constructor(private http: HttpClient, private cookieService: CookieService) {}

  login(user: User): Observable<any> {
    return this.http.post(this.loginUrl, JSON.stringify(user)).pipe(
      tap((res) => {
        localStorage.setItem("CURRENT_USER", JSON.stringify(res.employee));
        localStorage.setItem("APPSKILLS_TOKEN", res.token);
      })
    );
  }

  resetPassword(newPassowrd): Observable<any> {
    return this.http.post(this.resetPasswordUrl, newPassowrd);
  }

  changePassword(user): Observable<any> {
    return this.http.post(this.changePasswordUrl, user);
  }

  sendResetPasswordEmail(body: string): Observable<any> {
    return this.http.post(this.sendResetPasswordEmailUrl, body);
  }

  logout(): void {
    localStorage.removeItem("CURRENT_USER");
    localStorage.removeItem("APPSKILLS_TOKEN");
    this.cookieService.delete("REMEMBER_ME_COOKIE");
  }

  getCurrentUser(): any {
    return localStorage.getItem("CURRENT_USER");
  }

  getToken(): string {
    return localStorage.getItem("APPSKILLS_TOKEN");
  }

  isLoggedIn(): boolean {
    const helper = new JwtHelperService();

    return !helper.isTokenExpired(this.getToken());
  }
}
