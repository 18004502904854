import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SideBarComponent } from '../modules/side-bar/side-bar.component';
import { AppRoutingModule } from '../app-routing.module';
import { LanguageBoxComponent } from './components/language-box/language-box.component';
import { MaterialModule } from '../material.module';
import { SearchFilterComponent } from './components/search-filter/search-filter.component';
import { FormsModule } from '@angular/forms';
import { ListCategoriesComponent } from './components/list-categories/list-categories.component';

@NgModule({
  imports: [
    AppRoutingModule,
    CommonModule,
    TranslateModule,
    MaterialModule,
    FormsModule
  ],
  declarations: [
    LanguageBoxComponent,
    SideBarComponent,
    SearchFilterComponent,
    ListCategoriesComponent
  ],
  exports: [
    CommonModule,
    TranslateModule,
    SideBarComponent,
    SearchFilterComponent,
    LanguageBoxComponent,
    ListCategoriesComponent
  ]
})

export class SharedModule {}
