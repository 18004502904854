import { Component, OnInit } from '@angular/core';
import { User } from '../shared/user.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from '../shared/authentication.services';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { PasswordCheckService } from '../shared/password-check.service';
import { RegexEmailValidator } from 'src/app/shared/services/must-match.validator';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.pug',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  registerForm: FormGroup;
  user: User = new User();
  remember: boolean = false;
  submitted: boolean = false;
  credentialsError: boolean = false;
  passwordStrengthError: boolean = false;

  cookieValue = null;
  rememberMeCheckbox: boolean = false;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private cookieService: CookieService,
    private passwordCheckService: PasswordCheckService
  ) {}

  ngOnInit() {
    this.cookieValue = this.cookieService.get('REMEMBER_ME_COOKIE');
    this.cookieValue
      ? this.router.navigate(['/candidatures'])
      : this.authenticationService.logout();
    this.registerForm = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });
  }

  get f() {
    return this.registerForm.controls;
  }

  login() {
    this.submitted = true;
    this.credentialsError = false;

    this.authenticationService.login(this.user).subscribe(
      () => {
        if (this.rememberMeCheckbox === true) {
          let expiredDate = new Date();
          expiredDate.setDate(expiredDate.getDate() + 7);
          this.cookieService.set(
            'REMEMBER_ME_COOKIE',
            localStorage.getItem('APPSKILLS_TOKEN'),
            expiredDate
          );
          this.cookieValue = this.cookieService.get('REMEMBER_ME_COOKIE');
        }
        this.router.navigateByUrl('/candidatures');
      },
      error => (this.credentialsError = true)
    );
  }

  checkFormValidation(): boolean {
    this.passwordStrengthError = false;
    this.submitted = true;
    if (this.user && this.user.password) {
      if (this.passwordCheckService.checkPwdStrength(this.user.password)) {
        return !this.registerForm.invalid;
      }
      this.passwordStrengthError = true;
      return false;
    }
    return false;
  }

  registerOnValidatorChange(fn: () => void): void {}
}
