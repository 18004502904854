import { Component, OnInit, ViewChild, ElementRef, Input, SimpleChanges, SimpleChange, OnChanges } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-mail-template',
  templateUrl: './mail-template.component.pug'
})
export class MailTemplateComponent implements OnInit, OnChanges {

  /** Skeleton (from DOM) used to generate the mail template */
  @ViewChild('body') public body: ElementRef;
  /** Input from parent component used to set the message in the mail template */
  @Input() public message: string = "";
  /** URL to assets */
  public imageBaseURL: string = environment.adminAppUrl;
  /** English header image URL */
  public headerImageFrURL: string = `${environment.adminAppUrl}/assets/images/mail-template/evaluation_technique.png`;
  /** French header image URL */
  public headerImageEnURL: string = `${environment.adminAppUrl}/assets/images/mail-template/technical_evaluation.png`;
  /** Header image URL */
  public headerImageURL: string;
  /** The mail template */
  private mailBody: string;

  constructor() {}

  ngOnInit(): void {
    // Sets header image in mail template depending on the selected language
    this.headerImageURL = localStorage.getItem('appLang') === 'fr' ? this.headerImageFrURL : this.headerImageEnURL;
  }

  /**
   * Handles changes triggered by parent component, sets message input property
   * @param {SimpleChanges} changes overall changes triggered by the parent component
   */
  ngOnChanges(changes: SimpleChanges) {
    const currentItem: SimpleChange = changes.message;
    this.message = currentItem.currentValue;
    this.createMailTemplate();
  }
    
  /**
   * @description Creates mail template used to send remote test invitation 
   */
  private createMailTemplate(): void{
    this.mailBody = this.body.nativeElement.innerHTML;
    this.mailBody = this.mailBody.replace("MESSAGE", this.message);
    this.mailBody = this.mailBody.replace(/_ngcontent-c2=""/g, '');
    this.mailBody = this.mailBody.replace(/_ngcontent-c13=""/g, '');
    this.mailBody = this.mailBody.replace(/tbody/, 'table style="width: 718px; background-color: #E1E1E1; font-family: Helvetica; font-size: 18px; border-collapse:collapse; border: whitesmoke 2px solid;"');
    this.mailBody = this.mailBody.replace(/tbody/, 'table');
    this.mailBody = this.mailBody.replace(/"/g, '\'');
    this.mailBody = this.mailBody.replace(/\n/g, '');
  }

  /**
   * @description Returns mailBody property containing the mail template
   */
  get mailtemplate(): string {
    return this.mailBody;
  }

}
