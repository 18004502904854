import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuestionsLibraryComponent } from './questions-library/questions-library.component';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { MaterialModule } from '../../material.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSortModule, MatPaginatorModule } from '@angular/material';
import { CandidatureModule } from '../candidatures/candidatures.module';
import { SubtechnologyQuestionsComponent } from './subtechnology-questions/subtechnology-questions.component';
import { SideBarModule } from '../side-bar/side-bar.module';
import { LibraryComponents } from './questions-library.routes';
import { QuestionsEditComponent } from './questions-edit/questions-edit.component';
import { DeleteQuestionDialogComponent } from './delete-question-dialog/delete-question-dialog.component';
import { MatButtonToggleModule } from '@angular/material';
import { LibraryByFolderComponent } from './library-by-folder/library-by-folder.component';
import { CategoriesBoxComponent } from './library-by-folder/categories-box/categories-box.component';
import { TechnologiesLibraryComponent } from './technologies-library/technologies-library.component';


@NgModule({
  declarations: [LibraryComponents, CategoriesBoxComponent, DeleteQuestionDialogComponent, QuestionsEditComponent],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    BrowserModule,
    MatExpansionModule,
    MaterialModule,
    MatSortModule,
    MatPaginatorModule,
    MatExpansionModule,
    CandidatureModule,
    SideBarModule,
    MatButtonToggleModule
  ],
  exports: [QuestionsLibraryComponent, SubtechnologyQuestionsComponent, QuestionsEditComponent, MatButtonToggleModule, LibraryByFolderComponent, CategoriesBoxComponent, TechnologiesLibraryComponent],
  entryComponents: [SubtechnologyQuestionsComponent, QuestionsEditComponent, DeleteQuestionDialogComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA,],
})
export class QuestionsLibraryModule { }
