import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthGuard } from '../../shared/_guards/auth.guard';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { UpdatePasswordComponent } from './update-password/update-password.component';
import { UpdatePasswordManagerComponent } from './update-password-manager/update-password-manager.component';
import { ManagerCandidaturesComponent } from './manager-candidatures/manager-candidatures.component';

export const PROFILE_ROUTES = [
    { path: '', component: MyProfileComponent, canActivate: [AuthGuard] },
    { path: 'update-password-manager', component: UpdatePasswordManagerComponent, canActivate: [AuthGuard]},
];

export const myProfileComponents = [
    MyProfileComponent,
    UpdatePasswordComponent,
    UpdatePasswordManagerComponent,
    ManagerCandidaturesComponent
]

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: '', component: MyProfileComponent, canActivate: [AuthGuard],
                children: []
            }
        ])
    ],
    exports: [RouterModule]
})
export class UsersRoutingModule { }