import {
  Component,
  OnInit,
  ViewChild,
  OnChanges,
  AfterViewInit,
  Input,
  SimpleChanges,
  HostListener 
} from '@angular/core';
import {
  MatDialog,
  MatTableDataSource,
  MatPaginator,
  MatSort,
  MatDialogRef,
  PageEvent
} from '@angular/material';
import { Router } from '@angular/router';
import { CandidaturesSynthesisComponent } from '../candidatures-synthesis/candidatures-synthesis.component';
import { FiltersService } from '../shared/filters.service';
import { Candidature } from '../shared/candidature';
import { CandidaturesService } from '../shared/candidatures.service';

@Component({
  selector: 'app-candidatures-list',
  templateUrl: './candidatures-list.component.pug',
  styleUrls: ['./candidatures-list.component.scss']
})

export class CandidaturesListComponent implements OnInit, OnChanges {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Input() search: string;
  @Input() searchSD: Date;
  @Input() searchED: Date;
  
  dataSource: MatTableDataSource<Candidature>;
  initialList: Candidature[] = [];
  collection = { count: 60, data: [] };
  lowValue: number = 0;
  highValue: number = 20;
  rank;
  displayedColumns: string[] = [
    'email',
    'date',
    'rank',
    'finalScore',
    'subTechnologyName'
  ];
  displayedColumnsMobile: string[] = [
    'candidature'
  ];
  score= {
    maxScoreNoobie:20,
    maxScoreIntermidiare:50,
    maxScoreConfirmed:80,
    maxScoreMaster:95,
  }
  isMobileView = window.innerWidth < 768;

  constructor(
    public router: Router,
    public filterService: FiltersService,
    public dialog: MatDialog,
    private candidatureService: CandidaturesService,
  ) { }

  ngOnInit() {
    CandidaturesSynthesisComponent.triggerFilters.subscribe(list => {
      this.initialList = list;
      const skip = this.paginator.pageSize * this.paginator.pageIndex;
      const pagedData = this.initialList.filter((u, i) => i >= skip)
      .filter((u, i) => i <this.paginator.pageSize); 
      for (let data of pagedData) { //rank values for the five first items before any
        this.candidatureService.getCandidateRankByTestAndSubTech(data.idSubTech,data.idTest).subscribe(result => {
        data.rank=result[0] 
        })
      }
      this.initialList.forEach((candidature => {
        candidature['testPassedTime'] = this.getTestTime(candidature.testStartDate, candidature.testEndDate)
      }))
      this.dataSource = new MatTableDataSource(this.initialList);
      this.dataSource.paginator = this.paginator;
      this.dataSource.filter = this.search.trim().toLowerCase();
      this.dataSource.sort = this.sort;
      
    });

  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.dataSource) {
      this.dataSource.filter = this.search.trim().toLowerCase();
      const skip = this.paginator.pageSize * this.paginator.pageIndex;
      const pageData=this.dataSource.filteredData.slice(skip,skip + this.paginator.pageSize)
      for (let data of pageData){
        this.candidatureService.getCandidateRankByTestAndSubTech(data.idSubTech,data.idTest).subscribe(result => {
          data.rank=result[0] 
          })
      }
    } 
  }

  getPaginatorData(event: PageEvent){
    const skip = this.paginator.pageSize * this.paginator.pageIndex;
    let dataToUse = this.dataSource.filteredData;
    if (this.search.trim() === '') {
      dataToUse = this.initialList;
    }
    const pagedData = dataToUse.filter((u, i) => i >= skip)
    .filter((u, i) => i <this.paginator.pageSize); 
    for (let data of pagedData){
      this.candidatureService.getCandidateRankByTestAndSubTech(data.idSubTech,data.idTest).subscribe(result => {
        data.rank=result[0] 
        })
    }
    }

  /**
   * This function redirects to see more
   * @param id the candidature id
   */
  goToSeeMore(id: number, rang: string) {
    this.router.navigate(['/details/candidatures', id], { queryParams: { rank: rang } });
  }

  getTestTime (testStartDate:string , testEndDate:string):number {
    const testPassedTime = new Date(testEndDate).getTime() - new Date(testStartDate).getTime() ;
    return testPassedTime
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.isMobileView = event.target.innerWidth < 768; // Mettez à jour la variable lorsque la fenêtre est redimensionnée
  }
  
}
