import { appRoutesNames } from './app.routes.names';
import { TESTS_ROUTES } from './modules/tests/tests.routes';
import { LOGIN_ROUTES } from './modules/login/login.routes';
import { CANDIDATURES_ROUTES } from './modules/candidatures/candidatures.routes';
import { PROFILE_ROUTES } from './modules/my-profile/my-profile.routes';
import { USERS_ROUTES } from './modules/users/users.routes';
import { DETAILS_CANDIDATURE_ROUTE } from './modules/details-candidature/details-candidature.routes';
import { QUESTIONSLIBRARY_ROUTES } from './modules/questions-library/questions-library.routes';
import { AllRoleGuard } from './shared/_guards/all-role-guard.service';
import { ManagerAdminGuard } from './shared/_guards/manager-admin-gard.service';
import { AdminRoleGuard } from './shared/_guards/admin-role-guard';

export const APP_ROUTES = [
  { path: appRoutesNames.CANDIDATURES, children: CANDIDATURES_ROUTES, canActivate: [AllRoleGuard] },
  { path: appRoutesNames.TESTS, children: TESTS_ROUTES, canActivate: [ManagerAdminGuard] },
  { path: appRoutesNames.LOGIN, children: LOGIN_ROUTES },
  { path: appRoutesNames.PROFILE, children: PROFILE_ROUTES, canActivate: [ManagerAdminGuard] },
  { path: appRoutesNames.QUESTIONSLIBRARY, children: QUESTIONSLIBRARY_ROUTES, canActivate: [AdminRoleGuard] },
  { path: appRoutesNames.USERS, children: USERS_ROUTES, canActivate: [AdminRoleGuard] },
  { path: appRoutesNames.DETAILSCANDIDATURE, children: DETAILS_CANDIDATURE_ROUTE , canActivate: [ManagerAdminGuard]},
  { path: '', pathMatch: 'full', redirectTo: appRoutesNames.LOGIN }
];
