import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpHeaders
} from '@angular/common/http';
import { AuthenticationService } from 'src/app/modules/login/shared/authentication.services';
import { Observable, of } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

@Injectable()
export class CustomHeadersInterceptor implements HttpInterceptor {
  readonly apiBaseUrl = environment.apiUrl;

  constructor(
    private authenticationService: AuthenticationService,
    private cookieService: CookieService,) { }
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (this.authenticationService.getToken()) {
      const token = this.authenticationService.getToken();
      const headers = new HttpHeaders({
        'Authorization': `Bearer ${token}`,
        version: environment.frontVersion,
        locale: this.cookieService.get('LANG') || 'fr',
      });
      if (req.url.indexOf('/upload/file') !== -1 && !req.url.includes(`${this.apiBaseUrl}/questions`)) {
        headers.set('Content-Type', 'application/json; boundary=----WebKitFormBoundaryePkpFF7tjBAqx29L');
      }else{
        headers.set('Content-Disposition', 'multipart/form-data; boundary=----WebKitFormBoundaryePkpFF7tjBAqx29L');
      }
      req = req.clone({ headers: headers });
    } else {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        locale: this.cookieService.get('LANG') || 'fr',
      });
      req = req.clone({ headers: headers });
    }
    return next.handle(req);
  }

  private handleError<T>(result?: T) {
    return (error: any): Observable<T> => {
      console.error('error : ', error);
      return of(result);
    };
  }
}
