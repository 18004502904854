import {
  Component,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
  HostListener
} from '@angular/core';
import {
  MatTableDataSource,
  MatSort,
  PageEvent,
  MatPaginator
} from '@angular/material';
import { ToastrService } from "ngx-toastr";
import { Router } from '@angular/router';
import { CurrentUser } from 'src/app/shared/models/currentUser';
import { ManagerService } from '../shared/manager.service';
import { Candidat } from '../shared/candidat';
import { CandidaturesService } from '../../candidatures/shared/candidatures.service';

@Component({
  selector: 'app-manager-candidatures',
  templateUrl: './manager-candidatures.component.pug',
  styleUrls: ['./manager-candidatures.component.scss']
})
export class ManagerCandidaturesComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Output() usersLength: EventEmitter<number> = new EventEmitter();
  dataSource: MatTableDataSource<Candidat>;
  result: Candidat[] = [];
  config: any;
  collection = { count: 60, data: [] };
  currentUser: CurrentUser;

  displayedColumns: string[] = [
    'email',
    'date',
    'rank',
    'finalScore',
    'technologyName',
    'dybLink',
    'more'
  ];

  displayedColumnsMobile: string[] = [
    'candidature'
  ];

  initialTestsList: Candidat[] = [];
  isLoading: boolean = false;

  isMobileView = window.innerWidth < 768;

  constructor(
    private managerService: ManagerService,
    public router: Router,
    private candidatureService: CandidaturesService,
    private toastr: ToastrService,
  ) {}


  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('CURRENT_USER'));
    if (this.currentUser != null) {      
      this.getCandidateOfManager(this.currentUser.idEmployee);
    }
  }

  /**
  * This function init the candidatures test list
  * @param initialTestsList tests list
  */
  private initList(initialTestsList: Candidat[]): void {
    const skip = this.paginator.pageSize * this.paginator.pageIndex;
      const pagedData = initialTestsList.filter((u, i) => i >= skip)
      .filter((u, i) => i <this.paginator.pageSize); 
      for (let data of pagedData) { //rank values for the five first items before any
        this.candidatureService.getCandidateRankByTestAndSubTech(data.idSubTech,data.idTest).subscribe(result => {
        data.rank=result[0] 
        })
      }
    this.dataSource = new MatTableDataSource(initialTestsList);
    this.dataSource.paginator = this.paginator;
  }

  /**
  * This function redirects to see more
  * @param id the candidature id
  * @param rank the candidature rank
  */
  goToSeeMore(id: number, rank) {
    this.router.navigate(['/details/candidats-manager', id], {queryParams:{rank : rank}});
  }

  /**
   * This function get the manager's Candidates; uses the managerService's getCandidaturesManagerById method and save result in initialTestsList
   * @returns a list of candidatures
   */
  getCandidateOfManager(idEmployee) {
    this.managerService
      .getCandidaturesManagerById(idEmployee)
      .subscribe(results => {        
        this.initialTestsList = results;
        this.initialTestsList.sort((a,b)=> { return <any>new Date(b.testStartDate) - <any>new Date(a.testStartDate)});
        this.initList(this.initialTestsList);
      });
  }

  /**
   * @description Applies filter based on value from emitted event
   * @param event event emitted by a DOM element
  */
 SearchFilter(event: any): void {
   if (typeof event  === 'string') {
     event = event.trim().toLowerCase();
     this.dataSource.filter = event;
   }
 }

 getPaginatorData(event: PageEvent){
  const skip = this.paginator.pageSize * this.paginator.pageIndex;
  const pagedData = this.initialTestsList.filter((u, i) => i >= skip)
  .filter((u, i) => i <this.paginator.pageSize); 
  for (let data of pagedData){
    this.candidatureService.getCandidateRankByTestAndSubTech(data.idSubTech,data.idTest).subscribe(result => {
      data.rank=result[0] 
      })
  }
  }

  getDybProfile(emailAdress){
    this.isLoading = true;
    this.candidatureService.getDYBByEmail(emailAdress)
        .subscribe(
          data => {
            this.isLoading = false;
            this.toastr.success(
              `<div>
                  <span>Cliquez sur <a href="${data.cvLink}" target="_blank" class="link-style">ce lien</a> pour ouvrir le CV<span>
              </div>`,
              'Profil DYB',
              {
                disableTimeOut: true,
                enableHtml: true, // Activer le support HTML
              }
            );
          },
          error => {
            this.isLoading = false;
            this.toastr.error("L'adresse e-mail sélectionnée n'est associée à aucun profil DoYouBuzz. Nous vous recommandons de faire la recherche directement sur DoYouBuzz pour localiser le profil souhaité.",'', {
              disableTimeOut: true,
            });
          }
        );
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.isMobileView = event.target.innerWidth < 768; // Mettez à jour la variable lorsque la fenêtre est redimensionnée
  }
}

