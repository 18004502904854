import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-delete-user-dialog',
  templateUrl: './delete-user-dialog.component.pug',
  styleUrls: ['./delete-user-dialog.component.scss']
})
export class DeleteUserDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<DeleteUserDialogComponent>
  ) { }

  /**
   * @description Sends confirmation
   */
  public onSubmit(): void {
    this.dialogRef.close(true);
  }

  /**
   * @description Closes modal
   */
  public onClose(): void {
    this.dialogRef.close();
  }

}
