import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RolesService } from '../services/roles.service';

@Injectable({
    providedIn: 'root'
})
export class AllRoleGuard implements CanActivate {

    constructor(private rolesService: RolesService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const isAuthorized = this.rolesService.isAdmin() || this.rolesService.isManager() || this.rolesService.isReader()
        if (isAuthorized !== true) {
            this.router.navigate(['/candidatures']);
            return false
        }
        return true;
    }
}