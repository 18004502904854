import { Response } from 'express';
import { Component, Inject, OnInit } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { CustomErrorStateMatcher } from "src/app/shared/models/custom-error-state-matcher";
import { jsPDF } from "jspdf";
import { CandidaturesService } from "../../candidatures/shared/candidatures.service";
import { bgToB64 } from "../../../../assets/Image_B64/base64Img";
import { ToastrService } from "ngx-toastr";
import { ngxCsv } from 'ngx-csv/ngx-csv';

@Component({
  selector: "app-export-pdf-dialog",
  templateUrl: "./export-pdf-dialog.component.pug",
  styleUrls: ["./export-pdf-dialog.component.scss"],
})
export class ExportPdfDialog implements OnInit {
  /** The form */
  public form: FormGroup;
  /** errorMatcher used for formControls */
  public errorMatcher = new CustomErrorStateMatcher();
  /** The index for the user's role */
  public targetRoleIndex: number = 0;
  choiceList = {
    format: null,
    type: null,
  };
  public content: string;
  public anwerSheet;
  isValid = {
    settedFormatRapport: true,
    settedTypeRapprt: true,
  };
  isValidForm=false

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ExportPdfDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private candidatureService: CandidaturesService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.candidatureService
      .getAnswerSheet(this.data.candidature.idTest)
      .subscribe((answer) => {
        this.anwerSheet = answer;
      });
  }

  validateInput() {
    if (!this.choiceList.format)
      this.isValid.settedFormatRapport = false;

    if (!this.choiceList.type)
      this.isValid.settedTypeRapprt = false;

    if (
      this.isValid.settedFormatRapport &&
      this.isValid.settedTypeRapprt
    )
      this.isValidForm = true;
  }

  /**
   * @description Sends data if form is valid
   */
  public onSubmit(): void {
    this.validateInput()
    if(this.isValidForm){
      this.savePdf();
      this.dialogRef.close();
    }else{
      this.toastr.error("Tu dois choisir le format et le type du rapport");
    }
    
  }

  /**
   * @description Draw the background Image of a page
   * @param doc
   */
  public imgAsBgPdf(doc) {
    // Add the image as a background
    const imageOptions = {
      url: bgToB64,
      width: doc.internal.pageSize.getWidth(),
      height: doc.internal.pageSize.getHeight(),
    };
    doc.addImage(imageOptions.url, 'PNG', 0, 0, imageOptions.width, imageOptions.height);
  }
  /**
   * @description Draw rectangle at the top of the page for APPSKILLS text
   * @param doc
   */
  public textHeader(doc) {
    const pageWidth = doc.internal.pageSize.getWidth();
    this.rectDesignText(doc, ((pageWidth / 2) - 75), 10, 150, 103);
    this.textTitlePdf(doc, 68, 'APPSKILLS');
  }
  /**
   * @description writing the title of a PDF
   * @param doc
   * @param verticalPosition
   * @param text
   */
  public textTitlePdf(doc, verticalPosition, text?: string) {
    const pageWidth = doc.internal.pageSize.getWidth();
    const title = text ? text : `Rapport ${this.choiceList.type}`
    doc.setFontSize(24);
    doc.setFont('bold');
    doc.setTextColor(255, 255, 255);
    let dim = doc.getTextDimensions(title);
    doc.text((pageWidth / 2) - (dim.w / 2), verticalPosition, title);
    doc.setFont('normal');
    doc.setFontSize(12);
  }
  /**
   * @description Writing all data of a candidats ( email, test result....)
   * @param doc
   */
  public detailsTestCandidat(doc) {
    let x = 20;
    let y = 200;
    let rectangleHeight = 70
    let condidatureInfo = [
      { title: "Email", val: this.data.candidature.email },
      { title: "Manager", val: `${this.data.candidature.managerFirstName} ${this.data.candidature.managerLastName}` },
      { title: "Technos", val: this.data.candidature.technoDetails[0].name }
    ]
    // Compare the length of the text to the rectangle width to calculate the height of the two rectangle
    condidatureInfo = condidatureInfo.map(info => {
      let splitTitle = doc.splitTextToSize(info.val, 260 - (x + 70))
      if (splitTitle.length > 1) {
        rectangleHeight += (splitTitle.length - 1) * 20
      }
      return {
        title: info.title,
        val: doc.splitTextToSize(info.val, 260 - (x + 70))
      }
    })
    // Create the first Rectangle  for details Candidat
    this.rectDesignText(doc, 15, 180, 260, rectangleHeight);
    doc.setFont('bold');
    condidatureInfo.map(info => {
      doc.text(x, y, info.title);
      doc.text(x + 60, y, ":");

      info.val.map((val) => {
        doc.text(x + 70, y, val)
        y += 20;
      })

    })
    // Create the second Rectangle  for details Candidat
    x += 300
    y = 200
    this.rectDesignText(doc, 300, 180, 260, rectangleHeight);
    Object.entries([
      { title: "Test date", val: new Date(this.data.candidature.testDate).toDateString() },
      { title: "Test Score", val: `${this.data.candidature.technoDetails[0].score} ${"/"} 100 ` },
      { title: "Test duration", val: this.data.candidature.duration }
    ]).map(([k, v]) => {
      doc.text(x, y, v.title);
      doc.text(x + 90, y, ":");
      doc.text(x + 100, y, v.val);
      y += 20;
    })
    doc.setFont('normal');
  }
  /**
   * @description draw rectangle function
   * @param doc
   * @param x
   * @param y
   */
  public rectDesignText(doc, x, y, rectangleWidth, rectangleHeight) {
    doc.setFillColor(11, 21, 50);
    // Example : doc.rect(rectX, rectY, rectWidth, rectHeight, "F");
    doc.rect(x, y, rectangleWidth, rectangleHeight, 'F');
    doc.setTextColor(255, 255, 255);
  }

  /**
   * @description draw rectangle function
   * @param ResponseTab
   */
  public checkResponse(ResponseTab) {
    let wronganswer = ResponseTab.find(answer => answer[1] === false)
    if (wronganswer) {
      return 'false'
    }
    return 'true'
  }


  /**
   *
   * @param doc
   * @descriptionWriting Question && Answers
   */
  public writingTextQuestionAnswers(doc) {
    let x = 20;
    let y = 300;
    let pageNumber = 2;
    this.anwerSheet.map(
      (question) => {
        // if we got to the end of page we need to create a new one and put our postion to the top
        if (y >= 750) {
          doc.addPage()
          this.imgAsBgPdf(doc)
          x = 20;
          y = 40;
          this.PageNumber(doc, pageNumber);
          pageNumber++;
        }
        // Writing the question part
        doc.text(x, y, "Question");
        doc.text(x + 60, y, ":");
        let splitTitle = doc.splitTextToSize(question[0].toLowerCase(), doc.internal.pageSize.getWidth() - 100).filter(Boolean);
        splitTitle.map((line, i) => {
          doc.text(x + 70, y, line.toLowerCase());
          splitTitle.length !== i ? y += 18 : null;
        })
        // Wrting the answer part
        question[1].map(
          (answer) => {
            doc.text(x, y, "Réponse");
            doc.text(x + 60, y, ":");
            let splitTitle = doc.splitTextToSize(`${answer[0]}`, doc.internal.pageSize.getWidth() - 100).filter(Boolean);
            splitTitle.map(line => {
              doc.text(x + 70, y, line.toLowerCase());
              y += 18
            })
          }
        )

        // Writing if the answer is correct or not
        doc.text(x, y, "Correct");
        doc.text(x + 60, y, ":");
        doc.text(x + 70, y, this.checkResponse(question[1]));

        // draw a line after the question
        y += 25;
        doc.setLineWidth(1.2);
        doc.line(x, y, 560, y);
        y += 35;
      }
    );
  }

  /**
   * @param doc
   * @param PageCount
   * @description Write Pieds de la page
   */
  public PageNumber(doc, PageCount) {
    // Put text Color to white
    doc.setTextColor(255, 255, 255);
    doc.setFontSize(8);
    // Write footer in the bottom left
    doc.text(doc.internal.pageSize.getWidth() - 50, doc.internal.pageSize.getHeight() - 15, `Page ${PageCount}`);
    doc.setFontSize(12);
  }

  /**
   * @description Creates PDF
   */
  public async savePdf() {
    const doc = new jsPDF('p', 'pt', 'a4');
    // Draw background Page
    this.imgAsBgPdf(doc);
    //Create first counter in footer page
    this.PageNumber(doc, 1);
    // Draw the rectangle of AppSkills text on the top
    this.textHeader(doc);
    // Put the title of the Page
    this.textTitlePdf(doc, 150);
    // Write details in the rectangle that we have created
    this.detailsTestCandidat(doc);
    // Writing all Answers and Questions of the candidats
    this.choiceList.type !== "simple" && this.writingTextQuestionAnswers(doc);
    // Save the PDF
    doc.save(`${this.data.candidature.email}___${this.choiceList.type}.pdf`);
  }
  /**
   * @description Closes modal
   */
  public onClose(): void {
    this.dialogRef.close();
  }

  checked(format: string, element: string): void {
    this.choiceList[format] = element;
    if(this.choiceList.format){
      this.isValid.settedFormatRapport=true
    }
    if(this.choiceList.type){
      this.isValid.settedTypeRapprt=true
    }
  }

  public exportToCsv() {
    let candidateDetailsToExport = [
      {
        email: this.data.candidature.email,
        manager: `${this.data.candidature.managerFirstName} ${this.data.candidature.managerLastName}`,
        thecnos: this.data.candidature.technoDetails.map(techno=>techno.name).join(','),
        testDate: new Date(this.data.candidature.testDate).toDateString(),
        testScore: `${this.data.candidature.technoDetails.map(techno=>techno.score).join(',')} / 100`,
        testDuration: this.data.candidature.duration
      }
    ];
    let testDetailsToExport = this.anwerSheet.map(element=>{
      let question=element[0]
      let response
      let correct
      element[1].map(answer=>{
        response=answer[0]
        correct=answer[1]
      })
      return {question,response,correct}
    });
    const csvOptions = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: false,
      showTitle: false,
      title: '',
      useBom: false,
      noDownload: false
    };
  
    const options1 = {
      ...csvOptions,
      headers: ["email", "manager", "thecnos", "testDate", "testScore", "testDuration"]
    };
  
    const options2 = {
      ...csvOptions,
      headers: ["email", "manager", "thecnos", "testDate", "testScore", "testDuration", "question", "response", "correct"]
    };
    const dataToExport=candidateDetailsToExport.concat(testDetailsToExport)
    this.choiceList.type==="simple" ? new ngxCsv(candidateDetailsToExport, 'rapport simple', options1):new ngxCsv(dataToExport, 'rapport détaillé', options2)
  }
}
