import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../modules/login/shared/authentication.services';
import { Injectable } from '@angular/core';

/*
* GuestGuard: this service is used as a guard to avoid any connected user visiting the login page through its url
*/

@Injectable()
export class GuestGuard implements CanActivate {

  constructor(private authService: AuthenticationService,
              private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkLoggedIn();
  }

  checkLoggedIn() {
    this.authService.isLoggedIn() && this.router.navigate(["/candidatures"]);
    return !this.authService.isLoggedIn();
  }
}