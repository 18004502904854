import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatDialog } from '@angular/material';
import { User } from '../shared/user.model';
import { UsersService } from '../shared/users.service';
import { AddUserDialogComponent } from '../users-dialog/add-user-dialog/add-user-dialog.component';
import { UpdateUserDialogComponent } from '../users-dialog/update-user-dialog/update-user-dialog.component';
import { DeleteUserDialogComponent } from '../users-dialog/delete-user-dialog/delete-user-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.pug',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements OnInit {

  /** Users list */
  public users: User[] = [];
  /** Columns to display */
  public displayedColumns = ['lastName', 'firstName', 'email', 'actions'];
  /** Data source for the mat-tab */
  public dataSource = new MatTableDataSource([{}]);
  /** Messages to display with toastr Service */
  private messages = {
    success: {
      add: "",
      update: "",
      resent: "",
      delete: ""
    },
    errors: {
      add: "",
      alreadyExisted: "",
      update: "",
      resent: "",
      delete: ""
    }
  };

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private usersService: UsersService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.retrieveUsers();
    // Retrieves translated message from i18n folder
    this.translateService.get('users.messages').subscribe(
      messages =>{
        this.messages = Object.assign({}, messages);
      }
    );
  }

  /**
   * @description Gets users using user service
   */
  public retrieveUsers(): void {
    this.usersService.getUsers().subscribe(
      users => {
        this.users = users;
        this.dataSource = new MatTableDataSource(this.users);
        setTimeout(() => this.dataSource.paginator = this.paginator);
      },
      err => {
      }
    )
  }

  /**
   * @description Adds a new user by opening add-user-dialog modal
   */
  public addUser(): void {
    /** Shows Add user modal */
    let dialogRef = this.dialog.open(AddUserDialogComponent, {
      panelClass: 'myapp-no-border-radius-dialog'
    });

    dialogRef.afterClosed().subscribe(newUser => {

      if (newUser) {
        this.usersService.createUser(newUser).subscribe(
          newUser => {
            this.toastr.success(this.messages.success.add);
            // Refreshes users list
            this.retrieveUsers();
          },
          err => {
            if (err.status == 500) {
              this.toastr.error(this.messages.errors.alreadyExisted);
            } else {
              this.toastr.error(this.messages.errors.add);
            }
          }
        );
      }

    });

  }

  /**
   * @description Updates a user's information
   * @param {number} index index from mat-table in template
   * @param {string} idEmployee target's idEmployee
   * @param {string} lastName target's lastName
   * @param {string} firstName target's firstName
   * @param {string} email target's firstName
   * @param {string} role target's role
   */
  public updateUser(
    index: number,
    idEmployee: string,
    lastName: string,
    firstName: string,
    email: string,
    role: string
  ): void {

    /** Shows Update user modal and sends existing user data to it */
    let dialogRef = this.dialog.open(UpdateUserDialogComponent, {
      panelClass: 'myapp-no-border-radius-dialog',
      data:  {
        lastName: lastName,
        firstName: firstName,
        email: email,
        role: role
      }
    });

    dialogRef.afterClosed().subscribe(updatedUser => {

      if (updatedUser) {

        let updateOptions: User ={
          idEmployee: idEmployee,
          lastName: updatedUser.lastName,
          firstName: updatedUser.firstName,
          email: updatedUser.email,
          role: updatedUser.role,
        };

        this.usersService.updateUser(updateOptions).subscribe(
          updatedUser => {
            this.toastr.success(this.messages.success.update);
            // Refreshes users list
            this.retrieveUsers();
          },
          err => {
            this.toastr.error(this.messages.errors.update);
          }
        );
      }

    });
  }

  /**
   * @description Deletes a user
   * @param {string} idEmployee user id to delete
   */
  public deleteUser(idEmployee: string): void {

    /** Shows Confirmation modal */
    let dialogRef = this.dialog.open(DeleteUserDialogComponent, {
      width: '50%',
      panelClass: 'myapp-no-border-radius-dialog',
    });

    dialogRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        this.usersService.deleteUserById(idEmployee).subscribe(
          () => {
            this.toastr.success(this.messages.success.delete);
            // Refreshes users list
            this.retrieveUsers();
          },
          err => {
            this.toastr.error(this.messages.success.delete);
          }
        )
      }
    });

  }

  /**
   * @description Applies filter based on value from emitted event
   * @param event event emitted by a DOM element
   */
  public applyFilter(event: any): void {
    if (typeof event  === 'string') {
      event = event.trim().toLowerCase();
      this.dataSource.filter = event;
    }
  }

}
