import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SubTechnology } from '../../candidatures/shared/subtechnology';

@Injectable({
  providedIn: 'root'
})
export class SubTechnologyService {

  readonly apiBaseUrl = environment.apiUrl;
  readonly getTechSubcategoriesUrl = `${this.apiBaseUrl}/subtechnologies/technology/`;


  public subTechnology: Subject<SubTechnology>;

  constructor(private http:HttpClient) {
  }

  public getSubTechnologyById(id: string): Observable<any> {
      return this.http.get(`${this.apiBaseUrl}/subtechnologies/${id}`);
  }
  public getTechSubtechnologies(id:string): Observable<SubTechnology[]> {
    return this.http.get<SubTechnology[]>(`${this.getTechSubcategoriesUrl}${id}`);
  }

  public createSubTechno(subTechno: SubTechnology) {
    let addSubTechnology = this.http.post(`${this.apiBaseUrl}/subtechnologies/`, subTechno);
    return addSubTechnology;
  }
}
