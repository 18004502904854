import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material';
import { MaterialModule } from '../../material.module';
import { LoginComponent } from './login/login.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { loginComponents } from './login.routes';
import { GuestGuard } from '../../shared/_guards/guest.guard';


@NgModule({
  imports: [
    MatDatepickerModule,
    MaterialModule,
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  declarations: [
    loginComponents
  ],
  providers: [MatDatepickerModule, GuestGuard]
})
export class LoginModule { }
