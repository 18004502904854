import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { CustomErrorStateMatcher } from 'src/app/shared/models/custom-error-state-matcher';

@Component({
  selector: 'app-add-user-dialog',
  templateUrl: './add-user-dialog.component.pug',
  styleUrls: ['./add-user-dialog.component.scss']
})
export class AddUserDialogComponent implements OnInit {

  /** The form */
  public form: FormGroup;
  /** errorMatcher used for formControls */
  public errorMatcher = new CustomErrorStateMatcher();
  /** The index for the user's role */
  public targetRoleIndex: number = 0;
  /** Roles to display for the checkbox */
  public roleList: any[] = [
    {
      label: "users.roles.lecture",
      role: "lecture",
      isChecked: true
    },
    {
      label: "users.roles.manager",
      role: "manager",
      isChecked: false
    },
    {
      label: "users.roles.admin",
      role: "admin",
      isChecked: false
    }
  ];

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddUserDialogComponent>
  ) { }

  ngOnInit() {
    // Defines rules for the defined formControl
    this.form = this.fb.group({
      lastName: ['', Validators.required],
      firstName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      role: ['lecture', [Validators.required]] // default role will be lecture
    });
  }

  /**
   * @description Sends data if form is valid
   */
  public onSubmit(): void {
    if (this.form.valid) {
      this.dialogRef.close(this.form.value);
    } 
  }

  /**
   * @description Closes modal
   */
  public onClose(): void {
    this.dialogRef.close();
  }

  /**
   * @description Handles click selection for the user's role selection. Only one role should be selected
   * @param {number} event Event triggered by the DOM; should contain target index
   */
  public onClickSelection(event: number): void {

    for (let i = 0; i < this.roleList.length; i++) {
      if (event === i) {
        this.roleList[i].isChecked = true;
        this.form.controls['role'].setValue(this.roleList[i].role);
      } else {
        this.roleList[i].isChecked = false;
      }
    }
  }

}
