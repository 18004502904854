import { Component, Input, OnInit, SecurityContext, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MailTemplateComponent } from './mail-template/mail-template.component';
import { CustomErrorStateMatcher } from 'src/app/shared/models/custom-error-state-matcher';
import { ToastrService } from 'ngx-toastr';
import { Candidate } from '../shared/candidate.model';
import { User } from '../shared/user.model';
import { TestsService } from '../shared/tests.service';
import { forkJoin, Observable } from 'rxjs';
import { TechnoSelectionComponent } from './techno-selection/techno-selection.component';
import { RolesService } from 'src/app/shared/services/roles.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-tests-container',
  templateUrl: './tests-container.component.pug',
  styleUrls: ['./tests-container.component.scss'],
})
export class TestsContainerComponent implements OnInit {

  /** Mail title to display in the remote test invitation */
  private mailTitle: string = "";
  /** Upper mail body to display in the remote test invitation, the upper part is editable */
  public mailBodyUpperPart: string = "";
  /** Bottom mail body to display in the remote test invitation, the bottom part is not editable */
  public mailBodyBottomPart: string = "";
  /** Default mail body used for mailBodyUpperPart property */
  public defaultMailBodyUpperPart: string = "";
  /** Default mail body used for mailBodyBottomPart property */
  public defaultMailBodyBottomPart: string = "";
  /** Email form used to check if an email from input is valid */
  public emailForm: FormGroup;
  /** Stores all emails for sending remote test */
  public emailsList: string[] = [];
  /** errorMatcher used for formControls */
  public errorMatcher = new CustomErrorStateMatcher();
  /** Limit for adding email */
  private readonly emailsLimit: number = 5;
  /** Candidates list to use for sending remote test */
  public candidatesList: Candidate[] = [];
  /** Current user (should be a manager or an admin) */
  private currentUser: User;
  /** Messages to display with toastr Service */
  private messages = {
    success: {
      mailSending: "Mail has been sent"
    },
    errors: {
      missingTechno: "A type of test must be selected",
      mailLimit: "You have reached the email limit !",
      missingMail: "An email address is missing",
      mailBody: "Please check the body of your email",
      mailSending: "The email could not be sent"
    }
  };
  idSubTech: string[] = [];
  isChecked: boolean = false;
  isAdmin: boolean;
  public actualLang: string = "";
  /** Child component used to generate the mail template */
  @ViewChild('mailTemplate') mailTemplate: MailTemplateComponent;


  constructor(
    private sanitizer: DomSanitizer,
    private fb: FormBuilder,
    private translate: TranslateService,
    private toastr: ToastrService,
    private testsService: TestsService,
    private technoSelection: TechnoSelectionComponent,
    private rolesService: RolesService,
    private cookieService: CookieService,

  ) { }

  ngOnInit() {
    if (this.rolesService.isAdmin()) {
      this.isAdmin = true;
    }
    this.initEmailForm();
    this.getManagerName();
    this.actualLang = this.cookieService.get('LANG');

    // Retrieves translation from i18n folder
    this.translate.get('testReportMail').subscribe(
      testReportMail => {
        const { messages, mailBodyUpperPart, mailBodyBottomPart, mailTitle } = testReportMail;

        if (typeof messages === "object") {
          this.messages = Object.assign({}, messages);
        }

        if (mailBodyUpperPart) {
          this.defaultMailBodyUpperPart = mailBodyUpperPart;
          this.mailBodyUpperPart = this.defaultMailBodyUpperPart;
        }

        if (mailBodyBottomPart) {
          // the bottom part contains firstname.name to replace with the current user name
          this.defaultMailBodyBottomPart = this.setUserIdentity(mailBodyBottomPart);
          this.mailBodyBottomPart = this.defaultMailBodyBottomPart;
        }

        if (mailTitle) {
          this.mailTitle = mailTitle;
        }
      }
    );

  }

  /**
   * @description Inits reactive form for emails input
   */
  public initEmailForm(): void {
    const emailRegex = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$';
    this.emailForm = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.pattern(emailRegex)])]
    });
  }

  /**
   * @description Resets mail body. Use case: the user deletes the "links/lien" keyword in the mail body
   */
  public resetMailBody(): void {
    this.mailBodyUpperPart = this.defaultMailBodyUpperPart;
    this.mailBodyBottomPart = this.defaultMailBodyBottomPart;
    document.getElementById("message-editable").innerHTML = this.mailBodyUpperPart;
    document.getElementById("message-not-editable").innerHTML = this.mailBodyBottomPart;
  }

  /**
   * @description Get manager's (or admin) info from localStorage
   */
  public getManagerName(): void {
    /** Parses item 'CURRENT_USER' stored in localStorage */
    let user: any = JSON.parse(localStorage.getItem('CURRENT_USER'));

    const { idEmployee, firstName, lastName, email, role } = user;

    this.currentUser = {
      idEmployee: idEmployee,
      firstName: firstName,
      lastName: lastName,
      email: email,
      role: role
    };
  }

  /**
   * @description Sets user identity, it will replace the firstname.name signature in the email body with the user identity in currentUser property
   * @param {string} emailBody email body in html format
   */
  private setUserIdentity(emailBody: string): string {
    return emailBody.replace(/firstname.name/, `${this.currentUser.firstName} ${this.currentUser.lastName}`);
  }

  /**
   * @description Deletes an email address in emailsList
   * @param {number} index email's index from emailsList
   */
  public deleteEmail(index: number): void {
    this.emailsList.splice(index, 1);
  }

  /**
   * @description Adds an email address in emailsList depending on email formControl validity, emailsLimit property and email uniqueness
   */
  public addEmail(): void {
    // If email is valid and is unique, add email in emailsList and clear input
    if (this.emailForm.controls['email'].valid && !this.emailsList.includes(this.emailForm.get('email').value.trim())) {

      // If limit has not ben reached
      if (this.emailsList.length < this.emailsLimit) {
        this.emailsList.push(this.emailForm.get('email').value.trim());
        this.emailForm.reset();
      } else {
        this.toastr.error(this.messages.errors.mailLimit);
      }

    }
  }

  /**
   * @description Gets candidates'id by calling getOrCreateCandidateByEmail for each email in emailsList. The candidates' id will be used for sending remote test invitations
   */
  private getCandidatesId(choosenSubTech): Promise<void> {

    /** Stores http request service for each email */
    let httpRequests: Observable<Candidate>[] = [];

    // Pushes observable for each candidate in httpRequests
    this.emailsList.forEach((email) => {
      httpRequests.push(this.testsService.getOrCreateCandidateByEmail(email, this.currentUser.idEmployee,choosenSubTech));
    });

    return new Promise((resolve, reject) => {
      forkJoin(httpRequests).subscribe(
        (candidatesList: Candidate[]) => {
          this.candidatesList = candidatesList;
          resolve();
        },
        err => {
          reject(err);
        }
      );
    });
  }

  /**
  * This function return a boolean
  *
  */
  changeValidity() {
    this.isChecked = !this.isChecked;
  }

  /**
   * @description Sends remote test invitation to candidates
   * @param {string} htmlContent html content to send. It should be the mail template containing the customized message
   */
  private sendRemoteTestInvitations(htmlContent: string): Promise<void> {
    /** Stores http request service for each candidate */
    let httpRequests: Observable<any>[] = [];

    // Pushes observable for each candidate in httpRequests
    this.candidatesList.forEach((candidate: any) => {
      candidate.newTest.map((test,index) => httpRequests.push(this.testsService.sendTestInvitation(this.idSubTech[index], candidate.candidate, { htmlContent: htmlContent }, { title: this.mailTitle }, this.isChecked, test.id_test)))
    });

    return new Promise((resolve, reject) => {
      forkJoin(httpRequests).subscribe(
        infoList => {
          resolve();
        },
        err => {
          reject(err);
        }
      );
    });

  }

  /**
   * @description Sanitizes an html element to avoid XSS attacks
   * @param {HTMLElement} htmlElement the html element to sanitize
   */
  private sanitizeInnerHTML(htmlElement: HTMLElement): string {
    return this.sanitizer.sanitize(SecurityContext.HTML, htmlElement.innerHTML);
  }

  /**
   * @description Handles submit event in order to send remote test invitations to all candidates. The mail must contain the word "Lien/Link" and the emailsList property must contain at least one mail
   */
  public async onSubmit(): Promise<void> {
    // Checks if emails exist
    if (this.emailsList.length == 0) {
      this.toastr.error(this.messages.errors.missingMail);
      return;
    }

    // Checks if techno as been selected
    if (!this.idSubTech.length) {
      this.toastr.error(this.messages.errors.missingTechno);
      return;
    }
    // Sanitizes html body to avoid XSS attacks
    this.mailBodyUpperPart = this.sanitizeInnerHTML(document.getElementById("message-editable"));
    this.mailBodyBottomPart = this.sanitizeInnerHTML(document.getElementById("message-not-editable"));

    // Checks if link exists i.e <a href='generatedURL'> </a> must be present in message
    if (!this.mailBodyUpperPart.concat(this.mailBodyBottomPart).includes('href="generatedURL"')) {
      this.toastr.error(this.messages.errors.mailBody);
      return;
    }

    try {
      await this.getCandidatesId(this.idSubTech).catch(err => { throw new Error(err) });
      await this.sendRemoteTestInvitations(this.mailTemplate.mailtemplate).catch(err => { throw new Error(err) });
      this.toastr.success(this.messages.success.mailSending);

      // Resets email List and mail body
      this.emailsList = [];
      this.resetMailBody();

    } catch (err) {
      this.toastr.error(this.messages.errors.mailSending);
    }
  }
  doSomething(selectedSubTechnologies: string[]): void {
    this.idSubTech = selectedSubTechnologies;
  }
  sendLangToTechnoSelection(lang): void {
    this.actualLang = lang;
  }
}
