import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../../material.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { DetailsCandidatureComponent } from './details-candidature.component';
import { BrowserModule } from '@angular/platform-browser';
import { ExportPdfDialog } from './expot-pdf-dialog/export-pdf-dialog.component';

@NgModule({
  declarations: [
    DetailsCandidatureComponent, 
    ExportPdfDialog,
  ],
  entryComponents: [
    ExportPdfDialog,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule,
    MaterialModule,
    SharedModule,
    RouterModule,
  ]
})
export class DetailsCandidatureModule { }
