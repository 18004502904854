import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Result } from '../../shared/result';
import { ResultService } from '../../shared/result.service';

@Component({
  selector: 'app-candidatures-result-dialog',
  templateUrl: './candidatures-result-dialog.component.pug',
  styleUrls: ['./candidatures-result-dialog.component.scss'],
  providers: [ResultService]
})
export class CandidaturesResultDialogComponent implements OnInit {

  newResults = [];
  result: Result;
  resultInit: Result[];
  @Output() static triggerFilters: EventEmitter<Result[]> = new EventEmitter<Result[]>();

  constructor(
    public dialogRef: MatDialogRef<CandidaturesResultDialogComponent>,
    private resultService: ResultService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {

  }

  ngOnInit() {
    this.resultService.getResult().then(results => {
      this.resultInit = results;
      if (this.data && this.data.chosenFilters) {      
        results.forEach(result => {
          let results = result
          this.data.chosenFilters.forEach(filter => {
            if (results.name === filter.name) {
              results.status = filter.status;
            }
          })
        })
        this.resultInit = results;
      }
    });
  }

  onNoClick(state?: boolean): void {
    this.dialogRef.close();
  }

  /**
  * This function emit the results array & close the result dialog
  * @param triggerFilters event use to emit data
  * @param newResults levels array 
  */
  validate(): void {
    this.newResults = [];
    this.resultInit.forEach(element => {
      if (element.status) {
        this.newResults.push(element);
      }
    })
    this.dialogRef.close(this.newResults);
  }
}


